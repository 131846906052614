import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { apiNewCallBegan } from "./apinew"; 

const initialState = {
    data: null,
    loading: false,
    lastFetch: null,
    message: null,
    status: undefined,
    error: {},
    fosAndReportingToList: {
        data: null,
        loading: false,
        lastFetch: null,
        message: null,
        status: undefined,
        error: {},
    },
    fieldMisLocationList: {
        data: null,
        loading: false,
        lastFetch: null,
        message: null,
        status: undefined,
        error: {},
    },
}

const slice = createSlice({
    name: "userList",
    initialState,
    reducers: {
        userListRequested: (userList, action) => {
            userList.loading = true;
            userList.message = null;
            userList.status = undefined;
            userList.error = {};
        },
        userListReceived: (userList, action) => {
            userList.data = action.payload.data;
            userList.loading = false;
            userList.lastFetch = Date.now();
            userList.message = null;
            userList.status = "success";
            userList.error = {};
        },
        userListRequestFailed: (userList, action) => {
            userList.loading = false;
            userList.message = null;
            userList.status = undefined;
            userList.error =
                action.payload.response !== null ||
                    action.payload.response !== undefined
                    ? action.payload.response.data
                    : action.payload.message
        },

        fosAndReportingToListRequested: (userList, action) => {
            userList.fosAndReportingToList.loading = true;
            userList.fosAndReportingToList.message = null;
            userList.fosAndReportingToList.status = undefined;
            userList.fosAndReportingToList.error = {};
        },
        fosAndReportingToListReceived: (userList, action) => {
            userList.fosAndReportingToList.data = action.payload.data;
            userList.fosAndReportingToList.loading = false;
            userList.fosAndReportingToList.lastFetch = Date.now();
            userList.fosAndReportingToList.message = null;
            userList.fosAndReportingToList.status = "success";
            userList.fosAndReportingToList.error = {};
        },
        fosAndReportingToListRequestFailed: (userList, action) => {
            userList.fosAndReportingToList.loading = false;
            userList.fosAndReportingToList.message = null;
            userList.fosAndReportingToList.status = undefined;
            userList.fosAndReportingToList.error =
                action.payload.response !== null ||
                    action.payload.response !== undefined
                    ? action.payload.response.data
                    : action.payload.message
        },

        fieldMisLocationListRequested: (userList, action) => {
            userList.fieldMisLocationList.loading = true;
            userList.fieldMisLocationList.message = null;
            userList.fieldMisLocationList.status = undefined;
            userList.fieldMisLocationList.error = {};
        },
        fieldMisLocationListReceived: (userList, action) => {
            console.log("fieldmis.payload.data====", action.payload);
            userList.fieldMisLocationList.data = action.payload.data;
            userList.fieldMisLocationList.loading = false;
            userList.fieldMisLocationList.lastFetch = Date.now();
            userList.fieldMisLocationList.message = null;
            userList.fieldMisLocationList.status = "success";
            userList.fieldMisLocationList.error = {};
        },
        fieldMisLocationListRequestFailed: (userList, action) => {
            userList.fieldMisLocationList.loading = false;
            userList.fieldMisLocationList.message = null;
            userList.fieldMisLocationList.status = undefined;
            userList.fieldMisLocationList.error =
                action.payload.response !== null ||
                    action.payload.response !== undefined
                    ? action.payload.response.data
                    : action.payload.message
        },

        clearMessagesRequested: (userList) => {
            userList.status = undefined;
            userList.message = null;
            userList.error = {};
        },
        clearUserListStorageRequested: () => initialState,
    },
});

const {
    userListRequested,
    userListReceived,
    userListRequestFailed,
    fosAndReportingToListRequested,
    fosAndReportingToListReceived,
    fosAndReportingToListRequestFailed,
    fieldMisLocationListRequested,
    fieldMisLocationListReceived,
    fieldMisLocationListRequestFailed,
    clearMessagesRequested,
    clearUserListStorageRequested
} = slice.actions;
export default slice.reducer;

// Action Creators

// export const getUserList = (userId, agencyId=0, type="OTHER") => (dispatch, getState) => {
//     console.log("Invoking userList Api", userId);
//     dispatch(
//         apiCallBegan({
//             url: "/api/v1/service",
//             method: "post",
//             data: {
//                 feature: 'USER_LIST',
//                 userId,
//                 agencyId, 
//                 type
//             },
//             onStart: userListRequested.type,
//             onSuccess: userListReceived.type,
//             onError: userListRequestFailed.type,
//         })
//     );
// };

export const getUserList = (userId, agencyId=0, type="OTHER") => (dispatch, getState) => {
    console.log("Invoking userList Api", userId);
    dispatch(
        apiNewCallBegan({
            url: "/users/userList",
            method: "post",
            data: {
                feature: 'USER_LIST',
                userId,
                agencyId, 
                type
            },
            onStart: userListRequested.type,
            onSuccess: userListReceived.type,
            onError: userListRequestFailed.type,
        })
    );
};

export const getFosAndReportingToList = (userId) => (dispatch, getState) => {
    console.log("Invoking getFosAndReportingToList Api", userId);
    dispatch(
        apiCallBegan({
            url: "/api/v1/service",
            method: "post",
            data: {
                feature: 'GET_FOS_AND_REPORTING_TO',
                userId
            },
            onStart: fosAndReportingToListRequested.type,
            onSuccess: fosAndReportingToListReceived.type,
            onError: fosAndReportingToListRequestFailed.type,
        })
    );
};


export const fieldMisLocationLists = (userId) => (dispatch, getState) => {
    console.log("Invoking fieldMisLocationList Api", userId);
    dispatch(
        apiCallBegan({
            url: "/api/v1/service",
            method: "post",
            data: {
                feature: 'FIELD_MIS_LOCATION_LIST',
                userId: userId,
            },
            onStart: fieldMisLocationListRequested.type,
            onSuccess: fieldMisLocationListReceived.type,
            onError: fieldMisLocationListRequestFailed.type,
        })
    );
};

export const clearMessages = () => (dispatch) => {
    console.log("in clearMessages...");
    dispatch(clearMessagesRequested());
};

export const clearUserListStorage = () => (dispatch) => {
    console.log("in clearUserListStorageRequested...");
    dispatch(clearUserListStorageRequested());
};
