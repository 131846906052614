import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Alert from 'react-bootstrap/Alert';
// import LoadingOverlay from 'react-loading-overlay';
import Loader from "../../Loader/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { login, clearMessages } from "../../../store/authentication";
import { agencyClearStorage } from "./../../../store/agency";
import { financierClearStorage } from "./../../../store/financier";
import { emailClearStorage } from "./../../../store/checkEmail";
import { clearLoanStorage } from "./../../../store/loan";
import { clearLookupStorage } from "./../../../store/lookup";
import { clearUploadStorage } from "./../../../store/upload";
import { dashboardClearStorage } from "./../../../store/dashboard";
import { clearTodayStorage } from "./../../../store/today";
import { clearUserListStorage } from "./../../../store/userList";
import { clearLegalStorage } from "./../../../store/legal";
import { cleartrackLegalStorage } from "./../../../store/trackLegalNotice";
import { clearDataExportStorage } from "./../../../store/dataExport";
import { clearnotificationListStorage } from "./../../../store/noitificationList";
import { clearNotificationStorage } from "./../../../store/pushNotification";
import { clearLongRunningProcessStorage } from "./../../../store/longRunningProcess";
import { clearTeleCallerStorage } from "./../../../store/teleCaller";
import FirebaseNotifications from "../../../../FirebaseNotifications";
import { bucketWisePerformanceClearMessages,portfolioGoeCityClearMessages,portfolioGoeDistrictClearMessages,portfolioGoeStateClearMessages, posDistributionClearMessages } from "../../../store/summary";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  //const [pageLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let errorMsg = "Something went wrong. Please try again.";

  //console.log('Login page');

  const { status, error, loading } = useSelector((state) => state.entities.auth);
  console.log("got error...", error, status);

  
  useEffect(() => {
    dispatch(agencyClearStorage());
    dispatch(financierClearStorage());
    dispatch(emailClearStorage());
    dispatch(clearLoanStorage());
    dispatch(clearLookupStorage());
    dispatch(clearUploadStorage());
    dispatch(dashboardClearStorage());
    dispatch(clearTodayStorage());
    dispatch(clearUserListStorage());
    dispatch(clearLegalStorage());
    dispatch(cleartrackLegalStorage());
    dispatch(clearDataExportStorage());
    dispatch(clearnotificationListStorage());
    dispatch(clearNotificationStorage());
    dispatch(clearLongRunningProcessStorage());
    dispatch(clearTeleCallerStorage());
    dispatch(bucketWisePerformanceClearMessages());
    dispatch(portfolioGoeStateClearMessages());
    dispatch(portfolioGoeCityClearMessages());
    dispatch(portfolioGoeDistrictClearMessages());
    dispatch(posDistributionClearMessages());
  }, [])

  useEffect(() => {
    if (status === "error") {
      if (typeof error === "object") {
        errorMsg = error.message;
      }
      else {
        errorMsg = error;
      }
      setErrorAlertMsg(errorMsg);
      setShowErrorAlert(true);
      setTimeout(() => {
        dispatch(clearMessages());
      }, 2000);
    }

  }, [status]);

  useEffect(() => {
    if (status === "success" && !loading) {
      window.location.reload();
      setSuccessAlertMsg(status);
      setShowSuccessAlert(true);
    }
  }, [loading]);

  const { intl } = props;
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(1, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      dispatch(login(values.email, values.password));
    },
  });

  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChange = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
    
    {loading ? <Loader /> : " "} 
     
     {/* <LoadingOverlay
        active={loading}
        spinner
        text='Login...'
        styles={{
          wrapper: {
            overflow: loading ? 'hidden' : 'hidden'
          },
          spinner: (base) => ({
            ...base,
            width: '75px',
            '& svg circle': {
              stroke: '#00b1cc'
            }
          })
        }}
      > */}
        {/* begin::Head */}
        <div className="text-center mb-10 mb-lg-10">
          <img
            alt="Logo"
            className="max-h-40px m-auto"
            src={toAbsoluteUrl("/media/logos/collectix-logo.png")}
          />
        </div>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h3 font-weight-boldest letter-spacing">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
          <p className="text-muted">
            Enter your details to login to your account
          </p>
        </div>
        {/* end::Head */}

        {/*begin::Form*/}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {showErrorAlert &&
            <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
              {errorAlertMsg}
            </Alert>
          }

          {showSuccessAlert &&
            <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
              {successAlertMsg}
            </Alert>
          }

          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Registered E-Mail"
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container ml-6">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container ml-6">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex justify-content-end align-items-center ml-2 mr-0 mt-n2">
            <Link
              to="/auth/forgot-password"
              className="text-dark-50 text-hover-primary mr-2"
              id="kt_login_forgot"
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
          </div>
          <div className="d-flex justify-content-center justify-items-center">
            <button
              id="kt_login_signin_submit"
              type="submit"
              className={`btn btn-primary font-weight-bold px-9 py-4 my-3 signInBtn`}
            >
              <span>Sign In</span>
            </button>
          </div>
          <div className="d-flex justify-content-center justify-items-center login-privacy" >
            <div className="d-flex order-1 order-sm-2 my-2 login-privacy-link">
              {/* <a className="text-dark-75 text-hover-primary pl-4 pr-4" href='https://s3.ap-south-1.amazonaws.com/assetsandevidence.acenna.in/assets/AcennaPrivacyPolicy.pdf' target='blank' >
                Privacy
              </a>
          
              <a className="text-dark-75 text-hover-primary pl-4 pr-4" href='#'>
                Legal
              </a>
              <a className="text-dark-75 text-hover-primary pl-4 pr-4" href='#'>
                Contact
              </a> */}
              {/* </Link> */}
            </div>
          </div>

        </form>
        {/*end::Form*/}
        <FirebaseNotifications styles={{ display: 'none' }} />
      {/* </LoadingOverlay> */}
    </div>
  );
}

export default injectIntl(Login);
