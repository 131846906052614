import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";

import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const user = useSelector((state) => state.entities.auth.data);
  //console.log("menu list page user data:---", user);

  const [isAgency, setIsAgency] = useState(false);
  const [isCoordinator, setIsCoordinator] = useState(false);
  const [isMananger, setIsMananger] = useState(false);
  const [isTeamLeader, setIsTeamLeader] = useState(false);
  const [homeUrl, setHomeUrl] = useState();
  const [emailSegmentaion, setEmailSegmentaion] = useState(false);
  const [userCPV, setUserCPV] = useState(false);
  const [exception, setException] = useState(false);
  //console.log("emailSegmentaion======", emailSegmentaion);
  const [emailWeb, setEmailWeb] = useState(false);
  //console.log("emailWeb--123",emailWeb);

  useEffect(() => {
    if (user.role == "HR") {
      setHomeUrl("/campaign/sms-campaign");
    }
  }, [user]);

  useEffect(() => {
    //console.log(" user -------- ", user);
    if (user.role == "Agency Manager") {
      setIsAgency(true);
    }
    //console.log("user.role:", user.role);
  }, [isAgency]);

  useEffect(() => {
    if (user.role == "Central Coordinator") {
      setIsCoordinator(true);
    }
  }, [isCoordinator]);

  useEffect(() => {
    if (user.role == "Manager") {
      setIsMananger(true);
    }
  }, [isMananger]);

  useEffect(() => {
    if (user.role == "Team Leader/Supervisor") {
      setIsTeamLeader(true);
    }
  }, [isTeamLeader]);

  useEffect(() => {
    //console.log("user.role:", user.role);
    if (user.role == "Financier Manager") {
      setHomeUrl("/campaign/sms-campaign");
    } else if (
      user.role == "Team Leader/Supervisor" ||
      user.role == "Central Coordinator" ||
      user.role == "Manager"
    ) {
      // alert(' testing ')
      setHomeUrl("/campaign/sms-campaign");
    } else if (user.role == "HR") {
      setHomeUrl("/campaign/sms-campaign");
    } else {
      setHomeUrl("/campaign/sms-campaign");
    }

    if (
      user.email == "ace_amina@acenna.in" ||
      user.email == "ace_faizans@acenna.in" ||
      user.email == "anjum.shaikh@acenna.in"
    ) {
      setEmailSegmentaion(true);
    }
    if (user.email == "shivaji.sawant@acenna.in") {
      setUserCPV(true);
    }
    if (
      user.email == "shanmugam@acenna.in" ||
      user.email == "mdamirjaved888@gmail.com" ||
      user.email == "mariyah.khan@acenna.in"
    ) {
      setException(true);
    }
    if (
      user.email == "nilesh.k@acenna.in" ||
      user.email == "nilesh.r@acenna.in" ||
      user.email == "shanmugam@acenna.in" ||
      user.email == "shubham.suryavanshi@acenna.in" ||
      user.email == "operations@acenna.in" ||
      user.email == "mdamirjaved888@gmail.com" ||
      user.email == "shubham.mishra@acenna.in" ||
      user.email == "sayeed.ansari@acenna.in"
    ) {
      setEmailWeb(true);
    }
  }, [user]);

  // useEffect(() => {

  // }, [user]);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    //console.log('aside menu url:', url, location);
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
      "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  if (homeUrl) {
    return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/* {user.role !== "Telephone Agent" && user.role !== "HR" && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/dashboard/summary",
                false
              )}`}
              aria-haspopup="true"
            >
              {/* <NavLink
                exact
                className="menu-link"
                to={homeUrl}
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-4-blocks.svg"
                    )}
                  />
                </span>
                <span className="menu-text">
                  {user.role == "Telephone Agent" ||
                    user.role == "Financier Manager"
                    ? "Home"
                    : "Dashboard"}
                </span>
                <i className="menu-arrow" />
              </NavLink>
              <NavLink className="menu-link menu-toggle" exact to={homeUrl}>
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-4-blocks.svg"
                    )}
                  />
                </span>
                <span className="menu-text">
                  {user.role == "Telephone Agent" ||
                  user.role == "Financier Manager"
                    ? "Home"
                    : "Dashboard"}
                </span>
                <i className="menu-arrow" />
              </NavLink>

              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">
                        {user.role == "Telephone Agent" ||
                        user.role == "Financier Manager"
                          ? "Home"
                          : "Dashboard"}
                      </span>
                    </span>
                  </li>

                  {/* user.role == "Team Leader/Supervisor"
                  {(user.role == "Central Team" ||
                    user.role == "Team Leader/Supervisor" ||
                    user.role == "Central Coordinator" ||
                    user.role == "Manager" ||
                    user.role !== "HR" ||
                    user.role !== "Telephone Agent") && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/dashboard/agent-activities"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to="/dashboard/agent-activities"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Agent Activities</span>
                      </NavLink>
                    </li>
                  )}

                  {/* uncomment below role condition when Telephone Agent login
                  {user.role == "Telephone Agent" && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/dashboard/loan-list"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/dashboard/loan-list">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Loan List</span>
                      </NavLink>
                    </li>
                  )}

                  {user.role == "Financier Manager" && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/dashboard/financier-dashboard"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to="/dashboard/financier-dashboard"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Dashboard</span>
                      </NavLink>
                    </li>
                  )}
                  {user.role == "Financier Manager" && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/dashboard/summary"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/dashboard/summary">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Portfolio Cuts</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )} */}

          {/* {user.role !== "Telephone Agent" &&
            user.role !== "HR" &&
            user.role !== "Central Coordinator" && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //menu-item-open
                  "/dashboard",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/dashboard">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Layout/Layout-4-blocks.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Performance</span>
                  <i className="menu-arrow" />
                </NavLink>

                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">Performance</span>
                      </span>
                    </li>

                    {isAgency ? null : (
                      <>
                        {user.role == "Central Team" &&
                          user.role !== "Team Leader/Supervisor" &&
                          user.role !== "Central Coordinator" &&
                          user.role !== "Manager" &&
                          user.role !== "HR" &&
                          user.role !== "Financier Manager" && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/performance/bucket-wise-performance"
                              )}`}
                              aria-haspopup="true"
                            >
                              <NavLink
                                className="menu-link"
                                to="/performance/bucket-wise-performance"
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">Bucket Wise</span>
                              </NavLink>
                            </li>
                          )}
                      </>
                    )}

                    {user.role !== "Financier Manager" && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/performance/caller-wise-mis"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/performance/caller-wise-mis"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Tele Calling</span>
                        </NavLink>
                      </li>
                    )}

                    {user.role !== "Financier Manager" && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/performance/AprReports"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/performance/AprReports"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">APR Report</span>
                        </NavLink>
                      </li>
                    )}

                    {user.role !== "Agency Manager" &&
                      user.role !== "Financier Manager" &&
                      user.role !== "Team Leader/Supervisor" && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/performance/field-mis-performance"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/performance/field-mis-performance"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Field MIS</span>
                          </NavLink>
                        </li>
                      )}

                    {isAgency ? null : (
                      <>
                        {user.role == "Central Team" &&
                          user.role !== "Team Leader/Supervisor" &&
                          user.role !== "Central Coordinator" &&
                          user.role !== "Manager" &&
                          user.role !== "HR" &&
                          user.role !== "Financier Manager" && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/performance/geography-wise"
                              )}`}
                              aria-haspopup="true"
                            >
                              <NavLink
                                className="menu-link"
                                to="/performance/geography-wise"
                              >
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">
                                  Geography Wise
                                </span>
                              </NavLink>
                            </li>
                          )}
                      </>
                    )}

                    {user.role == "Central Team" &&
                      user.role !== "Team Leader/Supervisor" &&
                      user.role !== "Central Coordinator" &&
                      user.role !== "Manager" &&
                      user.role !== "HR" &&
                      user.role !== "Financier Manager" && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/performance/agency-wise-performance"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/performance/agency-wise-performance"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Allocation Wise</span>
                          </NavLink>
                        </li>
                      )}

                    {user.role == "Central Team" &&
                      user.role !== "Team Leader/Supervisor" &&
                      user.role !== "Central Coordinator" &&
                      user.role !== "Manager" &&
                      user.role !== "HR" &&
                      user.role !== "Financier Manager" && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/performance/financier-wise-performance"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/performance/financier-wise-performance"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Financier</span>
                          </NavLink>
                        </li>
                      )}
                  </ul>
                </div>
              </li>
            )} */}
          {/* ===================================Collection Trend===================================================*/}
          {/* {isAgency ? null : (
                      <>
                        {user.role == "Central Team" &&
                          user.role !== "Team Leader/Supervisor" &&
                          user.role !== "Central Coordinator" &&
                          user.role !== "Manager" &&
                          user.role !== "HR" &&
                          user.role !== "Financier Manager" && (
            
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //menu-item-open
                  "/CollectionTrendMonthDays",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/CollectionTrendDays"
                >
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Layout/Layout-4-blocks.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Collection Trend</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/CollectionTrendMonth"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/CollectionTrendMonth">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Month</span>
                      </NavLink>
                    </li>

                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/CollectionTrendDays"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/CollectionTrendDays">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Day</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
                          )}
          </>
                    )} */}
          {/* ======================================================================================= */}
          {/* {isAgency ? null : (
            <>
              {user.role !== "Telephone Agent" &&
                user.role !== "Team Leader/Supervisor" &&
                user.role !== "Central Coordinator" &&
                user.role !== "Manager" &&
                user.role !== "HR" && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      //menu-item-open
                      "/legal",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to="/legal">
                      <span className="svg-icon menu-icon">
                        <SVG src={toAbsoluteUrl("/media/svg/Stamp.svg")} />
                      </span>
                      <span className="menu-text">Automated legal</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <li
                          className="menu-item  menu-item-parent"
                          aria-haspopup="true"
                        >
                          <span className="menu-link">
                            <span className="menu-text">Legal</span>
                          </span>
                        </li>

                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/dashboard/segmentation"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/dashboard/autmated-legal"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Dashboard</span>
                          </NavLink>
                        </li>

                        {user.role !== "Financier Manager" && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/legal/upload"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink className="menu-link" to="/legal/upload">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Upload</span>
                            </NavLink>
                          </li>
                        )}
                        {user.role == "Financier Manager" && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/import/loanforlegal"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              to="/import/loanforlegal"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Loan For Legal</span>
                            </NavLink>
                          </li>
                        )}

                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/legal/notice"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/legal/notice">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              Generate Legal Notice
                            </span>
                          </NavLink>
                        </li>

                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/legal/track"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/legal/track">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              Track Legal Notice
                            </span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </li>
                )}
            </>
          )} */}
          {/* ====================================================================================== */}
          {/* {isAgency || isCoordinator ? (
            ""
          ) : (
            <>
              {(user.role == "Central Team" ||
                (user.role !== "Telephone Agent" &&
                  user.role !== "Team Leader/Supervisor" &&
                  user.role !== "Manager" &&
                  user.role !== "HR" &&
                  user.role !== "Central Coordinator" &&
                  user.role != null &&
                  user.role !== "Area Manager")) && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    //menu-item-open
                    "/legal",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to="#">
                    <span className="svg-icon menu-icon">
                      <SVG
                        fill="#fff"
                        src={toAbsoluteUrl("/media/svg/email.svg")}
                      />
                    </span>
                    <span className="menu-text">Email Segmentation</span>
                    <i className="menu-arrow" />
                  </NavLink>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className="menu-item  menu-item-parent"
                        aria-haspopup="true"
                      >
                        <span className="menu-link">
                          <span className="menu-text">Legal</span>
                        </span>
                      </li>

                      {user.role !== "Financier Manager" && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/EmailSegmentation/Upload"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/EmailSegmentation/Upload"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Upload</span>
                          </NavLink>
                        </li>
                      )}

                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/EmailSegmentation/track"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/EmailSegmentation/track"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Track Email</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              )}
            </>
          )}

          {emailSegmentaion && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                //menu-item-open
                "/legal",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="#">
                <span className="svg-icon menu-icon">
                  <SVG
                    fill="#fff"
                    src={toAbsoluteUrl("/media/svg/email.svg")}
                  />
                </span>
                <span className="menu-text">Email Segmentation</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Legal</span>
                    </span>
                  </li>

                  {user.role !== "Financier Manager" && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/EmailSegmentation/Upload"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to="/EmailSegmentation/Upload"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Upload</span>
                      </NavLink>
                    </li>
                  )}

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/EmailSegmentation/track"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/EmailSegmentation/track"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Track Email</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )} */}

          {/* {
            emailSegmentaion && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //menu-item-open
                  "/legal",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="#">
                  <span className="svg-icon menu-icon">
                    <SVG fill="#fff" src={toAbsoluteUrl("/media/svg/email.svg")} />
                  </span>
                  <span className="menu-text">Email Segmentation</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">Legal</span>
                      </span>
                    </li>

                    {user.role !== "Financier Manager" && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/EmailSegmentation/Upload"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/EmailSegmentation/Upload">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Upload</span>
                        </NavLink>
                      </li>
                    )}

                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/EmailSegmentation/track"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/EmailSegmentation/track">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">
                          Track Email
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            )} */}

          {/* {user.role !== "Telephone Agent" && user.role !== "HR" && (
            <li
              className={`menu-item ${getMenuItemActive("/today", false)}`}
              aria-haspopup="true"
            >
              <NavLink exact className="menu-link" to="/today">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Search.svg")} />
                </span>
                <span className="menu-text">Search Loan</span>
              </NavLink>
            </li>
          )} */}

          {isAgency ? null : (
            <>
              {(user.role == "Central Team" ||
                user.role == "Financier Manager" ||
                user.role == "Central Coordinator") && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/loan/loanList"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/loan/loanList">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Loan List</span>
                    </NavLink>
                  </li>
                )}
              {user.role !== "Telephone Agent" &&
                user.role !== "Team Leader/Supervisor" &&
                // user.role !== "Central Coordinator" &&
                user.role !== "Manager" &&
                user.role !== "HR" && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      //menu-item-open
                      "/dataImport",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    {user.role !== "Telephone Agent" && (
                      <NavLink
                        className="menu-link menu-toggle"
                        to="/dataImport"
                      >
                        <span className="svg-icon menu-icon">
                          <SVG
                            src={toAbsoluteUrl("/media/svg/icons/Upload.svg")}
                          />
                        </span>
                        <span className="menu-text">
                          {/* {user.role !== "Financier Manager" ? 'Import' : 'Upload'} */}
                          Import
                        </span>
                        <i className="menu-arrow" />
                      </NavLink>
                    )}
                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        <li
                          className="menu-item  menu-item-parent"
                          aria-haspopup="true"
                        >
                          <span className="menu-link">
                            <span className="menu-text">
                              {user.role !== "Financier Manager"
                                ? "Import"
                                : "Upload"}
                            </span>
                          </span>
                        </li>
                        {/* {(user.role == "Central Team" ||
                          user.role == "Financier Manager" ||
                          user.role == "Central Coordinator") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/import/loan"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink className="menu-link" to="/import/loan">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Loan</span>
                            </NavLink>
                          </li>
                        )} */}


                        {/* {(user.role == "Central Team" ||
                          user.role == "Financier Manager" ||
                          user.role == "Central Coordinator") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/import/APR"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink className="menu-link" to="/import/APR">
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">APR</span>
                            </NavLink>
                          </li>
                        )} */}
                        {/* {user.role !== "Financier Manager" && ( */}
                        <>
                          {(user.role == "Central Team" ||
                            user.role == "Financier Manager" ||
                            user.role == "Central Coordinator") && (
                              <li
                                className={`menu-item ${getMenuItemActive(
                                  "/import/allocation"
                                )}`}
                                aria-haspopup="true"
                              >
                                <NavLink
                                  className="menu-link"
                                  to="/import/allocation"
                                >
                                  <i className="menu-bullet menu-bullet-dot">
                                    <span />
                                  </i>
                                  <span className="menu-text">Allocation</span>
                                </NavLink>
                              </li>
                            )}
                          {/* <li
                            className={`menu-item ${getMenuItemActive(
                              "/import/disposition"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              to="/import/disposition"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Dispostion</span>
                            </NavLink>
                          </li> */}
                          {/* <li
                            className={`menu-item ${getMenuItemActive(
                              "/import/paidfile"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              to="/import/paidfile"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Payment</span>
                            </NavLink>
                          </li> */}
                          {/* {(user.role == "Central Team" ||
                            user.role == "Financier Manager") && (
                            <li
                              className={`menu-item ${getMenuItemActive(
                                "/import/users"
                              )}`}
                              aria-haspopup="true"
                            >
                              <NavLink className="menu-link" to="/import/users">
                                <i className="menu-bullet menu-bullet-dot">
                                  <span />
                                </i>
                                <span className="menu-text">User</span>
                              </NavLink>
                            </li>
                          )} */}
                        </>
                        {/* )} */}
                      </ul>
                    </div>
                  </li>
                )}
            </>
          )}

          {/* {user.role == "Central Team" && (
            <>
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //menu-item-open
                  "/dataImport",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/dataImport">
                  <span className="svg-icon menu-icon">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/Upload.svg")} />
                  </span>
                  <span className="menu-text">Tele Calling</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">Tele Calling</span>
                      </span>
                    </li>

                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/dashboard/loan-list"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink className="menu-link" to="/dashboard/loan-list">
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Loan List</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          )} */}

          {isAgency || isCoordinator ? null : (
            <>
              {user.role !== "Telephone Agent" &&
                user.role !== "Team Leader/Supervisor" &&
                user.role !== "Central Coordinator" &&
                user.role !== "Manager" && (
                  <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                      //menu-item-open
                      "/overview/agency",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to="/overview">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Layout/Layout-horizontal.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Overview</span>
                      <i className="menu-arrow" />
                    </NavLink>
                    <div className="menu-submenu ">
                      <i className="menu-arrow" />
                      <ul className="menu-subnav">
                        {/* {(user.role == "Central Team" ||
                          // user.role !== "HR" ||
                          user.role == "Central Coordinator") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/overview/financier"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              to="/overview/financier"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Financier</span>
                            </NavLink>
                          </li>
                        )} */}
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/overview/agency"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink className="menu-link" to="/overview/agency">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Agency</span>
                          </NavLink>
                        </li>
                        {/* {(user.role == "HR" || user.role == "Central Team") && (
                          <li
                            className={`menu-item ${getMenuItemActive(
                              "/overview/interviewList"
                            )}`}
                            aria-haspopup="true"
                          >
                            <NavLink
                              className="menu-link"
                              to="/overview/interviewList"
                            >
                              <i className="menu-bullet menu-bullet-dot">
                                <span />
                              </i>
                              <span className="menu-text">Interview List</span>
                            </NavLink>
                          </li>
                        )} */}
                      </ul>
                    </div>
                  </li>
                )}
            </>
          )}
          {/* {isAgency || user.role === "Financier Manager" ? null : ( */}
          {/* <>
            {user.role !== "Telephone Agent" &&
              user.role !== "HR" &&
              user.role !== "Team Leader/Supervisor" && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/data-export",
                    false
                  )}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/data-export">
                    <span className="svg-icon menu-icon">
                      <SVG
                        src={toAbsoluteUrl("/media/svg/icons/Download.svg")}
                      />
                    </span>
                    <span className="menu-text">
                      {" "}
                      {user.role === "Financier Manager"
                        ? "Report"
                        : "Export"}{" "}
                    </span>
                  </NavLink>
                </li>
              )}
          </> */}
          {/* )} */}

          {/* {isAgency || user.role === "Financier Manager" ? null : (
            <>
              {user.role !== "Telephone Agent" &&
                user.role !== "HR" &&
                user.role !== "Central Coordinator" &&
                user.role !== "Team Leader/Supervisor" && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/notification",
                      false
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/notification">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Notifications1.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Notification</span>
                    </NavLink>
                  </li>
                )}
            </>
          )} */}

          {/* =================== Campaign here ==================== */}

          {user.role !== "Telephone Agent" &&
            user.role !== "HR" &&
            user.role !== "Central Coordinator" && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //menu-item-open
                  "/campaign",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/campaign">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Chat2.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Campaigns</span>
                  <i className="menu-arrow" />
                </NavLink>

                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">Campaigns</span>
                      </span>
                    </li>

                    {/* {user.role !== "Financier Manager" && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/campaign/sms-campaign"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/campaign/sms-campaign"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Sms Campaign</span>
                        </NavLink>
                      </li>
                    )} */}
                    {user.role != "Financier Manager" && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/campaign/campaign"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/campaign/campaign"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">SMS Campaign</span>
                        </NavLink>
                      </li>
                    )}

                    {/* {user.role !== "Financier Manager" && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/campaign/add-sms-campaign"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/campaign/add-sms-campaign"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Add Campaign</span>
                        </NavLink>
                      </li>
                    )} */}

                    {/* {user.role !== "Financier Manager" && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/campaign/select-sms-campaign"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/campaign/select-sms-campaign"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Select Campaign</span>
                        </NavLink>
                      </li>
                    )} */}
                  </ul>
                </div>
              </li>
            )}

          {/* =================== Telecalling here ==================== */}
          {user.role !== "Financier Manager" && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/telecalling/telecalling"
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to="/telecalling/telecalling"
              >
                <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Active-call.svg"
                      )}
                    />
                </span>
                <span className="menu-text">Telecalling</span>
              </NavLink>
            </li>
          )}
          {/* {user.role !== "Telephone Agent" &&
            user.role !== "HR" &&
            user.role !== "Central Coordinator" && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //menu-item-open
                  "/telecalling",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/telecalling">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Active-call.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Telecalling</span>
                  <i className="menu-arrow" />
                </NavLink>

                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">Telecalling</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
            )} */}

          {/* =================== Field here ==================== */}

          {user.role !== "Telephone Agent" &&
            user.role !== "HR" &&
            user.role !== "Central Coordinator" && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //menu-item-open
                  "/field",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/field/field">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Shield-user.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Field</span>
                  {/* <i className="menu-arrow" /> */}
                </NavLink>

                {/* <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">Field</span>
                      </span>
                    </li>

                    {user.role !== "Financier Manager" && (
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/field/field"
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink
                          className="menu-link"
                          to="/field/field"
                        >
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Coming soon</span>
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div> */}
              </li>
            )}

          {/* =================== Legal here ==================== */}
          {user.role !== "Financier Manager" && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/legal/legal"
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to="/legal/legal"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Clipboard-check.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Legal</span>
              </NavLink>
            </li>
          )}
          {/* {user.role !== "Telephone Agent" &&
            user.role !== "HR" &&
            user.role !== "Central Coordinator" && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //menu-item-open
                  "/legal",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/legal">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Clipboard-check.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Legal</span>
                  <i className="menu-arrow" />
                </NavLink>

                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">Legal</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
            )} */}

          {/* =================== Payments here ==================== */}
          {user.role !== "Financier Manager" && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/payments/payments"
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to="/payments/payments"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Money.svg"
                    )}
                  />
                </span>
                <span className="menu-text"> Payments</span>
              </NavLink>
            </li>
          )}
          {/* {user.role !== "Telephone Agent" &&
            user.role !== "HR" &&
            user.role !== "Central Coordinator" && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //menu-item-open
                  "/payments",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/payments">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Money.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Payments</span>
                  <i className="menu-arrow" />
                </NavLink>

                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className="menu-item  menu-item-parent"
                      aria-haspopup="true"
                    >
                      <span className="menu-link">
                        <span className="menu-text">Payments</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </li>
            )} */}

          {/* =================== Segmentation / Campaigns here ==================== */}

          {/* {user.role !== "Telephone Agent" &&
            user.role != "Team Leader/Supervisor" &&
            user.role != "Central Coordinator" &&
            user.role != "Manager" &&
            user.role != "HR" && (
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  //menu-item-open
                  "/dashboard",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/dashboard">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Layout/Layout-horizontal.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Segmentation / Campaigns</span>
                  <i className="menu-arrow" />
                </NavLink>
                <div className="menu-submenu ">
                  <i className="menu-arrow" />
                  <ul className="menu-subnav">
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/dashboard/segmentation"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to="/dashboard/segmentation"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Dashboard</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            )} */}

          {/* =================== Attendance dashboard here ==================== */}

          {/* {(user.role == "Central Team" ||
            user.role == "Team Leader/Supervisor" ||
            user.role == "Central Coordinator" ||
            user.role == "Manager" ||
            user.role !== "Telephone Agent" ||
            user.role == "HR" ||
            user.role == "Area Manager") && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                //menu-item-open
                "/Attendance",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/Attendance">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-horizontal.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Attendance</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/Attendance/Dashboard_Attendance"
                    )}`}
                    aria-haspopup="true"
                  >
                    {user.role !== "Telephone Agent" && (
                      <NavLink
                        className="menu-link"
                        to="/Attendance/Dashboard_Attendance"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Dashboard</span>
                      </NavLink>
                    )}
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/Attendance/Accept_Reject"
                    )}`}
                    aria-haspopup="true"
                  >
                    {user.role !== "Telephone Agent" && (
                      <NavLink
                        className="menu-link"
                        to="/Attendance/Accept_Reject"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Accept/Reject</span>
                      </NavLink>
                    )}
                  </li>

                  {user.role == "Central Coordinator" ? (
                    ""
                  ) : (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/Attendance/Biometric_Attendance"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        to="/Attendance/Biometric_Attendance"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Biometric</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          )} */}

          {/* =================== Attendance dashboard End here ==================== */}

          {/* =================== Leave Application here ==================== */}
          {/* {(user.role == "Central Team" ||
            user.role == "Team Leader/Supervisor" ||
            user.role == "Central Coordinator" ||
            user.role == "HR" ||
            user.role == "Manager") && (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                //menu-item-open
                // "/LeaveApplication",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/LeaveApplication">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-horizontal.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Leave</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/LeaveApplication"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/LeaveApplication">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Individual</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${
                      getMenuItemActive()
                      // "/LeaveApplication"
                    }`}
                    aria-haspopup="true"
                  >
                    {user.role !== "Telephone Agent" &&
                      user.role !== "Central Coordinator" && (
                        <NavLink className="menu-link" to="/leaveDashTeamTabs">
                          <i className="menu-bullet menu-bullet-dot">
                            <span />
                          </i>
                          <span className="menu-text">Team</span>
                        </NavLink>
                      )}
                  </li>
                  {exception ? (
                    <li
                      className={`menu-item ${
                        getMenuItemActive()
                        // "/LeaveApplication"
                      }`}
                      aria-haspopup="true"
                    >
                      {user.role !== "Telephone Agent" &&
                        user.role !== "Central Coordinator" && (
                          <NavLink className="menu-link" to="/LeaveException">
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Exception</span>
                          </NavLink>
                        )}
                    </li>
                  ) : null}
                </ul>
              </div>
            </li>
          )} */}
          {/* =================== Leave Application End here ==================== */}

          {/* {isAgency || isCoordinator || isMananger || isTeamLeader ? null : (
            <>
              {(user.role == "Central Team" ||
                user.role == "Financier Manager" ||
                user.role == "Team Leader/Supervisor" ||
                user.role == "Manager" ||
                (user.role !== "HR" &&
                  user.role !== "Central Coordinator")) && (
                <li
                  className={`menu-item menu-item-submenu ${getMenuItemActive(
                    //menu-item-open
                    "/dataImport",
                    true
                  )}`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  {user.role !== "Telephone Agent" && (
                    <NavLink className="menu-link menu-toggle" to="/dataImport">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl("/media/svg/icons/Upload.svg")}
                        />
                      </span>
                      <span className="menu-text">
                        CPV
                      </span>
                      <i className="menu-arrow" />
                    </NavLink>
                  )}
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      <li
                        className={`menu-item ${getMenuItemActive(
                          "/import/Verification_Import"
                        )}`}
                        aria-haspopup="true"
                      >
                      </li>

                      {(user.role == "Central Team" ||
                        user.role == "Financier Manager") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/import/Verification_Allocation"
                          )}`}
                          aria-haspopup="true"
                        >
                        </li>
                      )}

                      {(user.role == "Central Team" ||
                        user.role == "Financier Manager") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/import/Verification_Import"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/import/Verification_Import"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">CPV Import</span>
                          </NavLink>
                        </li>
                      )}
                      {(user.role == "Central Team" ||
                        user.role == "Financier Manager") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/import/Verification_Allocation"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/import/Verification_Allocation"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">CPV Allocation</span>
                          </NavLink>
                        </li>
                      )}

                      {(user.role == "Central Team" ||
                        user.role == "Financier Manager") && (
                        <li
                          className={`menu-item ${getMenuItemActive(
                            "/import/Search_CPV"
                          )}`}
                          aria-haspopup="true"
                        >
                          <NavLink
                            className="menu-link"
                            to="/import/Search_CPV"
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">Search CPV</span>
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
              )}
            </>
          )} */}

          {/* ------------------------- User Personal CPV ------------------------- */}

          {/* {userCPV ? (
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                //menu-item-open
                "/dataImport",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/dataImport">
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Upload.svg")} />
                </span>
                <span className="menu-text">
                  CPV
                </span>
                <i className="menu-arrow" />
              </NavLink>

              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">
                        {user.role !== "Financier Manager"
                          ? "Import"
                          : "Upload"}
                      </span>
                    </span>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/import/Verification_Import"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/import/Verification_Import"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">CPV Import</span>
                    </NavLink>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/import/Verification_Allocation"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/import/Verification_Allocation"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">CPV Allocation</span>
                    </NavLink>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/import/Search_CPV"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/import/Search_CPV">
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Search CPV</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          ) : null} */}

          {/* ------------------------- User Persoanl CPV ------------------------- */}

          {/* {user.role === "Telephone Agent" && (
            <li
              className={`menu-item ${getMenuItemActive(
                "/dashboard/loan-list",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink exact className="menu-link" to="/dashboard/loan-list">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Layout/Layout-4-blocks.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Loan List</span>
              </NavLink>
            </li>
          )} */}
          {/* {(user.role !== "Central Team" &&
                user.role !== "Telephone Agent" &&
                user.role !== "Financier Manager" &&
                user.role !== "Team Leader/Supervisor" &&
                user.role !== "Manager" &&
                user.role !== "HR" &&
                user.role !== "Central Coordinator") && (
          <li
            className={`menu-item ${getMenuItemActive("/website", false)}`}
            aria-haspopup="true"
          >
            <NavLink exact className="menu-link" to="/website">
              <span className="svg-icon menu-icon">
                <SVG fill="#fff"
                  src={toAbsoluteUrl("/media/svg/web-icon.svg")}
                />
              </span>
              <span className="menu-text">Website</span>
            </NavLink>
          </li>
                  )} */}

          {emailWeb ? (
            <li
              className={`menu-item ${getMenuItemActive("/website", false)}`}
              aria-haspopup="true"
            >
              <NavLink exact className="menu-link" to="/website">
                <span className="svg-icon menu-icon">
                  <SVG
                    fill="#fff"
                    src={toAbsoluteUrl("/media/svg/web-icon.svg")}
                  />
                </span>
                <span className="menu-text">Website</span>
              </NavLink>
            </li>
          ) : null}
          {/* <li
            className={`menu-item ${getMenuItemActive("/help", false)}`}
            aria-haspopup="true"
          >
            <NavLink exact className="menu-link" to="/help">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Question-circle.svg")}
                />
              </span>
              <span className="menu-text">Help</span>
            </NavLink>
          </li> */}
          {/* <li
            className={`menu-item ${getMenuItemActive("/temp-dashboard", false)}`}
            aria-haspopup="true"
          >
            <NavLink exact className="menu-link" to="/temp-dashboard">
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Question-circle.svg")}
                />
              </span>
              <span className="menu-text">Temp Dashboard</span>
            </NavLink>
          </li> */}
        </ul>

        {/* end::Menu Nav */}
      </>
    );
  } else {
    return <></>;
  }
}
