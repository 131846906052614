import React, { useState, useEffect } from "react";
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardVoice, MdOutlineSms } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io5";

// import launchCampaign from "../../files/launch-campaign";
import launchCampaign from "../../files/launch-campaign.png"
import { NavLink } from "react-router-dom/cjs/react-router-dom.js";

 function Campaign() {
    return (
        <div className="row m-0 loanDataImport launchcampaign">
            <div className="w-100 d-flex p-5 bg-white cardsec">
                <div className="lfsec flex-fill">
                    <img
                        src={launchCampaign}
                        alt="Launch Campaign"
                        style={{ width: "100%", maxWidth: "100%" }}
                    />
                </div>
                <div className="rtsec flex-fill ml-3">
                    <h4>Launch a campaign to make some noise</h4>
                    <div className="subhd">Select your campaign type to proceed</div>
                    <div className="mt-5">
                        <div className="campaigncard d-flex p-5 bd-highlight align-items-center border border-secondary">
                            <div className="lficon p-3">
                                <MdOutlineKeyboardVoice />
                            </div>
                            <div className="rtcont ml-3">
                                <h5>Voice</h5>
                                <div className="subhd">Launching your Voice campaigns globally has never been easier</div>
                            </div>
                            <div className="ml-auto pl-5 arrowright">
                                <MdOutlineKeyboardArrowRight />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                    <NavLink
                          className="menu-link"
                          to="/campaign/sms-campaign"
                        >
                        <div className="campaigncard d-flex p-5 bd-highlight align-items-center border border-secondary">
                            <div className="lficon p-3">
                            <MdOutlineSms />
                            </div>
                            <div className="rtcont ml-3">
                                <h5>SMS</h5>
                                <div className="subhd">Launching your Voice campaigns globally has never been easier</div>
                            </div>
                            <div className="ml-auto pl-5 arrowright">
                            <MdOutlineKeyboardArrowRight />
                            </div>
                        </div>

                        </NavLink>
                        
                    </div>
                    <div className="mt-5">
                        <div className="campaigncard d-flex p-5 bd-highlight align-items-center border border-secondary">
                            <div className="lficon p-3">
                            <IoLogoWhatsapp />
                            </div>
                            <div className="rtcont ml-3">
                                <h5>WhatsApp</h5>
                                <div className="subhd">Drive Sales, Support & Marketing Conversations on the World's most popular messaging App</div>
                            </div>
                            <div className="ml-auto pl-5 arrowright">
                            <MdOutlineKeyboardArrowRight />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Campaign