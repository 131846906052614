import React, { useEffect, useState } from 'react'
import Papa from 'papaparse'; // Import papaparse
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, usePagination, useSortBy } from "react-table";
import matchSorter from "match-sorter";
import { useDebounce } from 'use-debounce';
import { useHistory } from "react-router-dom";
import customSwal from "../components/customSwal.js";
import ReactModal from "react-modal";
// import {FaRegFileExcel } from "react-icons/fa";
import { FaRegFileExcel, FaRegTimesCircle } from "react-icons/fa";
import { CiCircleMinus, CiCirclePlus } from "react-icons/ci";
import Modal from "react-modal";
import Select from "react-select";
import ReactTable from "../../../src/app/modules/ReactTable.js"
import { postData, getData } from '../store/utils.js';
import { Card, CardBody, CardHeader } from '../../_metronic/_partials/controls/Card.js';
import Loader from '../modules/Loader/Loader.js';

function SelectallCampaign({sendDataToParent}) {
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [csvData, setCsvData] = useState([]); // State to store CSV data
  const [searchResultData, setSearchResultData] = useState([]);
  const [selecteAllRecords, setSelecteAllRecords] = useState(0);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [columns, setColumns] = useState([]);  // Holds the dynamically generated columns
  const [debouncedValue] = useDebounce(searchBoxValue, 1000); // 500ms debounce delay
  const [closeBtn, setCloseBtn] = useState(false)
  const [file, setFile] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [templateValue, setTemplateValue] = useState([])
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalAdvanceSearchIsOpen, setModalAdvanceSearchIsOpen] = React.useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [campaignDetail, setCampaignDetails] = useState([]);
  const [sendSelectedRow, setSendSelectedRow] = useState([])
  const [fieldNameOptions, setFieldNameOptions] = useState([])
  const [operationOptions, setOperationOptions] = useState([])
  const [fieldNameValue, setFieldNameValue] = useState([])
  const [rows, setRows] = useState([
    { fieldName: null, operation: null, value: "", errorMessage: "" },
  ]);


  // console.log(fieldNameOptions, "fieldNameOptionsfieldNameOptions")
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      overflow: "none",
      padding: "0px",
      width: "25%",
    },
  };

  const operationNumbersOptions = [
    { value: ">", label: ">" },
    { value: ">=", label: ">=" },
    { value: "<", label: "<" },
    { value: "<=", label: "<=" },
    { value: "=", label: "=" },
    { value: "!=", label: "!=" },
  ]

  const operationStringOptions = [
    { value: "=", label: "=" },
    { value: "like", label: "like" },
  ]

  const history = useHistory();
  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
      setPageIndex(page);
    }
  };


  // Handle sorting changes
  const handleSort = (column) => {
    // let newOrderBy = "asc";
  
    // if (sortBy === column.id) {
    //   newOrderBy = orderBy === "asc" ? "desc" : "asc";
    // }
    // setSortBy(column.id);
    // setOrderBy(newOrderBy);
    // getuploadedFileList(pageIndex, pageSize, column.id, newOrderBy);
  };

  const showFieldList = async () => {
    try {
      const response = await getData('loan/getFilterFieldsData');
      const data = response?.data;
      // console.log("templateData === ", data?.loanFields);
      const newData = data?.loanFields.map(item => ({
        value: item.dataType,
        label: item.columnName
      }));
      // console.log(newData, "newData0000")
      setFieldNameOptions(newData || [])

    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('Error fetching data');
    } finally {
      setIsLoadingOn(false);
    }
  }

  // Handler to update field values dynamically
  const handleModalformChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      { fieldName: null, operation: null, value: "", errorMessage: "" },
    ]);
  };

  // Handler to remove a row
  const handleRemoveRow = (index) => {
    const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
    setRows(updatedRows);
  };

  const handleCheckboxChange = (rowId) => {
    setSelectedRows((prevSelectedRows) => {
      // Check if the rowId is already in the selected rows array
      if (prevSelectedRows.includes(rowId)) {
        return prevSelectedRows.filter((id) => id !== rowId); // Deselect the checkbox
      } else {
        return [...prevSelectedRows, rowId,]; // Select the checkbox
      }
    });
  };
  const handleSelectAll = async () => {
    console.log("csvData.length == ", csvData.length, "selectedRows.length == ", selectedRows.length)
    if (csvData.length === 0) return; // Avoid running if csvData is empty
    if (selectedRows.length === totalRecords) {
      setSelectedRows([]);
      return;
    }
  
    try {
      // Select all records by calling the API with selecteAllRecords = 1
      const response = await getuploadedFileList(pageIndex, pageSize, sortBy, orderBy, "1");
  
      // Set all rows in response as selected
      const data = response?.data || [];
      setSelectedRows(data.map((row) => row));
    } catch (error) {
      console.error("Error selecting all records:", error);
      setErrorMessage("Error selecting all records");
    }
  };

  const getuploadedFileList = async (page = 0, pageSize = 10, sortBy = "", orderBy = "", selecteAllRecords = "0") => {
    const currentPage = pageIndex + 1
    try {
      let apiCall = `loan/getLoanList?page=${currentPage}&pageSize=${pageSize}&sortBy=${orderBy}&orderBy=${sortBy}`;
  
      if (selecteAllRecords === "1") {
        apiCall += "&selectedAllRecords=1";
      }
      const response = await getData(`${apiCall}`);

      const data = response?.data || [];

      setCsvData(data);
      setTotalRecords(response?.pagination?.totalRecords)
      setPageCount(response?.pagination?.totalPages)
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
      setErrorMessage('Error fetching data');
    } finally {
      setIsLoadingOn(false);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalAdvanceSearchIsOpen(false)
    setRows([{ fieldName: null, operation: null, value: '' }]);
  };

  const handleTemplateValue = async (selectedOption) => {
    setTemplateValue(selectedOption); // Set the selected option state
  }

  const handleChange = (e) => {
    setCampaignName(e.target.value)
  }

  const submitData = () => {
    if (selectedRows.length === 0) {
      customSwal.fire({
        icon: "error",
        title: "Error",
        text: "Please select at least one record to proceed.",
      });
    } else {
      // setIsOpen(true);
      // showTemplateList();
      getSelectedData()      
    }
    
  }

  const advanceFilter = () => {
    setModalAdvanceSearchIsOpen(true);
    showFieldList();
  }

  const getSelectedData = () => {
    console.log(selectedRows, "selectedRows")
    const newData = selectedRows.map(item => ({
      loanId: item.loanId,
      mobile: item.mobile
    }));
    const csv = Papa.unparse(newData);
    // Step 2: Convert CSV to Base64
    const base64 = btoa(csv);
    setSendSelectedRow(base64)

    const modalData = {
      rows : base64, 
      rowLength : selectedRows?.length,
      key : "selectCampaign"
    }
    sendDataToParent(modalData)
  }

  const handleModalSubmit = async () => {
    // Perform validation (optional)
    const isValid = rows.every(
      (row) => row.fieldName && row.operation && row.value.trim() !== ""
    );

    if (!isValid) {
      alert("Please fill in all fields in all rows.");
      return;
    }

    // Prepare the data for submission
    const formattedData = rows.map((row) => ({
      fieldName: row.fieldName.label, // Assuming `fieldName` is an object with `value` and `label`
      operation: row.operation.value,
      value: row.value,
    }));

    let filterData = {
      searchArray: formattedData,
    };
    try {
    const tempData = await postData("loan/advanceSearch", filterData);

    setModalAdvanceSearchIsOpen(false)
      const respData = tempData?.data || [];
      setCsvData(respData);
      setTotalRecords(tempData?.totalRecords);
      setPageCount(tempData?.totalPages);

      // Select all records from the response
      setSelectedRows(respData.map((row) => row));
    } catch (error) {
      console.error("Error during advanced search:", error);
      alert("An error occurred during the search. Please try again.");
    } finally {
      setRows([{ fieldName: null, operation: null, value: "" }]);
    }
  }

  // const handleSubmit = async () => {
  //   const newData = selectedRows.map(item => ({
  //     loanId: item.id,
  //     mobile: item.label
  //   }));

  //   if (!sendSelectedRow || sendSelectedRow.length === 0 || sendSelectedRow == "") {
  //     customSwal.fire({
  //       icon: "error",
  //       title: "Error!",
  //       text: "Pls select Records to add in Campaign"
  //     })
  //   }

  //   let filterData = {
  //     smsFileName: campaignName,
  //     file: sendSelectedRow,
  //   };
    
  //   const tempData = await postData("sms/selectSmsCampaign", filterData);
    
  //   if (tempData.statusCode == 200) {
  //     setIsOpen(false);
  //     customSwal.fire({
  //       icon: "success",
  //       title: "Success",
  //       text: tempData?.message
  //     })
  //     history.push("/campaign/sms-campaign");
  //   }
  //   else {
  //     customSwal.fire({
  //       icon: "error",
  //       title: "Error!",
  //       text: tempData?.message || "Something went wrong!!!"
  //     })
  //   }
  // }

  // useEffect(() => {
  //   if (debouncedValue) {
  //     getuploadedFileList(debouncedValue);
  //   }
  //   getuploadedFileList();
  // }, [pageIndex, pageSize, debouncedValue]);

  useEffect(() => {
    getuploadedFileList(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  useEffect(() => {
  }, [selectedRows, csvData]); // Runs when selectedRows is updated

  const handleInputChange = (e) => {
    const searchVal = e.target.value;
    setSearchBoxValue(searchVal)
    if (searchVal.length > 0) {
      setCloseBtn(true)
    }
  };

  const handleFieldNameChange = (index, selectedOption) => {
    const updatedRows = [...rows];
    updatedRows[index].fieldName = selectedOption; // Update only the fieldName
    setRows(updatedRows);
    if (rows[0]?.fieldName?.value == "int") {
      setOperationOptions(operationNumbersOptions)
    } else if (rows[0]?.fieldName?.value == "varchar") {
      setOperationOptions(operationStringOptions)
    } else {
      setOperationOptions(operationNumbersOptions)
    }
  };

  const handleOperationChange = (index, selectedOption) => {
    const updatedRows = [...rows];
    updatedRows[index].operation = selectedOption; // Update only the operation
    setRows(updatedRows);
  };

  const handleValueChange = (index, value) => {
    const updatedRows = [...rows];
    updatedRows[index].value = value;
    setRows(updatedRows);
  };

  const columnsToShow = [
    {
      Header: (
        <input
          type="checkbox"
          onChange={handleSelectAll}
          checked={selectedRows?.length === totalRecords && totalRecords > 0}
        />
      ),
      accessor: 'select',
      Cell: ({ row }) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(row.original)}
          onChange={() => handleCheckboxChange(row.original)}
        />
      ),
      disableSortBy: true,
      disableFilters: true,
    },
    // { Header: 'S.No', accessor: (row, i) => i + 1 + pageIndex * pageSize },
    { Header: 'Loan Id', accessor: 'loanId' },
    { Header: 'Mobile', accessor: 'mobile' },
    // { Header: 'Email', accessor: 'email' },
    { Header: 'Full Name', accessor: 'fullName' },
  ];

  return (
    <div>
      {isLoadingOn && <Loader />}
      <div className="row m-0 loanDataImport">
        <div className="col-12 pl-0 pr-0 customCardDiv">
          <Card className="uploadedDataCard">
            <CardHeader className="d-flex justify-content-between align-items-center">
              {/* <div className="col-lg-3 d-flex justify-content-start align-items-center pl-3">
                <h4 className="pl-0 mb-0">Select Campaign</h4>
              </div> */}
              
              <div className="col-lg-12 d-flex justify-content-end pr-3">
                <button
                  type="button"
                  className="btn btn-primary font-weight-bold"
                  onClick={() => advanceFilter()}
                >
                  filter
                </button>
                {/* {sendSelectedRow && (
                  <button
                    type="button"
                    className="btn btn-primary font-weight-bold transformBtn agentTransformBtn ml-2"
                    onClick={() => runCampaign()}
                  >
                    Proceed
                  </button>
                )} */}
                {selectedRows.length > 0 ? 
                  <button type="button" onClick={() => submitData()} className='btn btn-primary font-weight-bold transformBtn agentTransformBtn ml-2'>Submit</button>
                   : 
                   ""
                }
                

              </div>
            </CardHeader>
            <CardBody className="customCardBody">
            <div className="table-responsive commontable">
              {csvData && csvData.length > 0 ? (
                <ReactTable
                  columns={columnsToShow}
                  data={csvData}
                  pageCount={pageCount}
                  pageIndex={pageIndex}
                  pageSize={pageSize}
                  setPageIndex={setPageIndex}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  handleGotoPage={handleGotoPage}
                  errorMessage={errorMessage}
                  handleSort={handleSort}
                  sortBy={sortBy}
                  orderBy={orderBy}
                />
              ) : (
                <p>No data available</p>
              )}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>

      {/* Modal Section */}
      <Modal
        isOpen={modalAdvanceSearchIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
          content: {
            margin: 'auto',
            top: "20%",
            padding: '20px',
            maxWidth: '60%',
            // maxHeight: '300px',
            borderRadius: '10px',
            border: '1px solid #ccc',
            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)',
          },
        }}
        ariaHideApp={false}
        contentLabel="Add Campaign Modal"
      >
        <div className="modalHeader d-flex justify-content-between align-items-center mb-3">
          <h4 className="modalTitle mb-0">Filter</h4>
          <button
            className="btn btn-link modalCloseBtn"
            style={{ fontSize: '1.5rem', lineHeight: '1', color: '#007bff' }}
            onClick={closeModal}
          >
            <FaRegTimesCircle />
          </button>
        </div>
        <div className="modalBody">
          {rows.map((row, index) => (

            <div className='row' key={index}>
              <div className='col-sm-3'>
                <Select
                  placeholder="Select Field Name"
                  options={fieldNameOptions}
                  value={row.fieldName}
                  // onChange={handleFieldName}
                  onChange={(selectedOption) => handleFieldNameChange(index, selectedOption)}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      // maxHeight: "200px", // Adjust height for scrollable dropdown
                      // overflowY: "auto",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? "#f0f8ff" : "white", // Change background on hover
                      color: state.isFocused ? "black" : "gray", // Change text color on hover
                      cursor: "pointer",
                    }),
                  }}
                />
                <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>
              </div>
              <div className='col-sm-3'>
                <Select
                  placeholder="Select Opration"
                  options={operationOptions}
                  value={row.operation}
                  onChange={(selectedOption) => handleOperationChange(index, selectedOption)}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      maxHeight: "200px", // Adjust height for scrollable dropdown
                      overflowY: "auto",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isFocused ? "#f0f8ff" : "white", // Change background on hover
                      color: state.isFocused ? "black" : "gray", // Change text color on hover
                      cursor: "pointer",
                    }),
                  }}
                />
                <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>
              </div>
              <div className='col-sm-4'>
                <input
                  type="text"
                  className="mb-4"
                  style={{ width: "100%", border: "1px solid hsl(0,0%,80%)", height: "36px", padding: "5px 10px", borderRadius: "4px" }}
                  value={row.value}
                  onChange={(e) => handleValueChange(index, e.target.value)}
                  placeholder="Enter Value"
                />
                <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>
              </div>
              <div className="col-sm-2">
                {rows.length === 1 ? (
                  // If only one row, show only the Add button
                  <CiCirclePlus
                    style={{ fontSize: "30px", cursor: "pointer" }}
                    onClick={handleAddRow}
                  />
                ) : index === rows.length - 1 ? (
                  // For the last row, show Add and Remove buttons
                  <>

                    <CiCircleMinus
                      style={{ fontSize: "30px", cursor: "pointer" }}
                      onClick={() => handleRemoveRow(index)}
                    />
                    <CiCirclePlus
                      style={{ fontSize: "30px", cursor: "pointer", marginRight: "10px" }}
                      onClick={handleAddRow}
                    />
                  </>
                ) : (
                  // For other rows, show only the Remove button
                  <CiCircleMinus
                    style={{ fontSize: "30px", cursor: "pointer" }}
                    onClick={() => handleRemoveRow(index)}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
        <div className='row'>
          <div className='col-sm-12'>
            <button
              className="btn btn-primary font-weight-bold transformBtn agentTransformBtn"
              type="button"
              onClick={handleModalSubmit}
            >
              Submit
            </button>
            <button
              className="btn btn-secondary font-weight-bold ml-2"
              type="button"
              onClick={closeModal}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      {/* */}
      {/* <ReactModal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={{
                  overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
                  content: {
                      margin: 'auto',
                      padding: '20px',
                      maxWidth: '500px',
                      // maxHeight: '200px',
                      borderRadius: '10px',
                      border: '1px solid #ccc',
                      boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)',
                  },
              }}
            ariaHideApp={false}
            contentLabel="Run Campaign Modal"
        >
            <div className="modalHeader d-flex justify-content-between align-items-center mb-3">
                <h4 className="modalTitle mb-0">Run Campaign</h4>
                <button
                    className="btn btn-link modalCloseBtn"
                    style={{ fontSize: "1.5rem", lineHeight: "1", color: "#007bff" }}
                    onClick={closeModal}
                >
                    <FaRegTimesCircle />
                </button>
            </div>
            <div className="modalBody">
                <Select
                    placeholder="Field Name"
                    // options={templateOptions}
                    // value={templateValue}
                    // onChange={handleTemplateValue}
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            maxHeight: "200px", // Adjust height for scrollable dropdown
                            overflowY: "auto",
                        }),
                    }}
                />
                <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
                <div>
                <Select
                    placeholder="Opration"
                    // options={templateOptions}
                    // value={templateValue}
                    // onChange={handleTemplateValue}
                    styles={{
                        menu: (provided) => ({
                            ...provided,
                            maxHeight: "200px", // Adjust height for scrollable dropdown
                            overflowY: "auto",
                        }),
                    }}
                />
                <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
                </div>

                <div>
                <input
                    placeholder="Opration"
                    // options={templateOptions}
                    // value={templateValue}
                    // onChange={handleTemplateValue}
                    className='form-control'
                />
                <div style={{ color: "red", marginTop: "10px" }}>{errorMessage}</div>
                </div>

                <div style={{ textAlign: "center", marginTop: 20 }}>
                    <button
                        className="btn btn-primary font-weight-bold transformBtn agentTransformBtn"
                        type="button"
                        // onClick={handleSubmit}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </ReactModal> */}
        {/* <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={{
                overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)' },
                content: {
                    margin: 'auto',
                    padding: '20px',
                    maxWidth: '500px',
                    maxHeight: '200px',
                    borderRadius: '10px',
                    border: '1px solid #ccc',
                    boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)',
                },
            }}
            ariaHideApp={false}
            contentLabel="Add Campaign Modal"
          >
            <div className="modalHeader d-flex justify-content-between align-items-center mb-3">
                <h4 className="modalTitle mb-0">Add Campaign</h4>
                <button
                    className="btn btn-link modalCloseBtn"
                    style={{ fontSize: '1.5rem', lineHeight: '1', color: '#007bff' }}
                    onClick={closeModal}
                >
                    <FaRegTimesCircle />
                </button>
            </div>
            <div className="modalBody">
                <input
                    type="text"
                    className="form-control mb-4"
                    onChange={handleChange}
                    value={campaignName}
                    placeholder="Enter campaign name"
                />
                <div style={{ color: 'red', marginBottom: '10px' }}>{errorMessage}</div>
                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <button
                        className="btn btn-primary font-weight-bold transformBtn agentTransformBtn"
                        type="button"
                        onClick={() => handleSubmit()}                     >
                        Submit
                    </button>
                </div>
            </div>
          </Modal> */}
    </div>
  );

}

export default SelectallCampaign


// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy, //sort data
    usePagination //use pagination
  );

  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  //const firstPageRows = rows.slice(0, 10);

  return (
    <>
      <div>
        <div className="tblScroll someradiush">
          <table {...getTableProps()} className="loanTable bucketWiseTable">
            <thead className="tableHead">
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className="tableHeader"
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {// Render the header
                        column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
              {/* <tr>
                <th
                  colSpan={visibleColumns.length}
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                  />
                </th>
              </tr> */}
            </thead>
            {/* <thead className="tfootmngbuckt">
              {footerGroups.map((group) => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map((column) => (
                    <td {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </td>
                  ))}
                </tr>
              ))}
            </thead> */}
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()} className="tableTd">
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{rows.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <br />
      </div>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
