import React, { useState, useEffect } from "react";
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../_metronic/_partials/controls";
import Loader from "../Loader/Loader";
import { getData, postData } from "../../store/utils.js";
import { useLocation } from "react-router-dom";
import ReactModal from "react-modal";
import { FaRegFileAlt, FaRegTimesCircle } from "react-icons/fa";
import { IoIosLink } from "react-icons/io";
import { FcGoogle } from "react-icons/fc";
import { MdCurrencyRupee } from "react-icons/md";
import { MdOutlinePayments } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import customSwal from "../../components/customSwal.js";
import { useTable, useFilters, useGlobalFilter, usePagination, useAsyncDebounce, useSortBy } from 'react-table';
import matchSorter from 'match-sorter'
import Table from "../ReactTable"
import { FaFileDownload } from "react-icons/fa";



function LoanDetails() {
    const [data, setData] = useState([]);
    const [historyData, setHistoryData] = useState([])
    const [disposition, setDisposition] = useState([])
    const location = useLocation();
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const queryParams = new URLSearchParams(location.search);
    const loanId = queryParams.get("loanId"); // Retrieve the loanId from the query string
    const id = queryParams.get("id"); // Retrieve the loanId from the query string
    const [modalIsOpen, setIsOpen] = useState(false);
    const [historyModalIsOpen, setHistoryModalIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date()); // Default to today's date
    const [promiseDate, setPromiseDate] = useState(null)
    const [paymentDate, setPaymentDate] = useState(null)
    const [selectedReason, setSelectedReason] = useState("");
    const [paymentMode, setPaymentMode] = useState("");
    const [paymentAmount, setPaymentAmount] = useState("");
    const [utr, setUtr] = useState("");
    const [remark, setRemark] = useState(""); // State to store textarea content
    const [showLoanId, setshowLoanId] = useState('')
    const [showId, setshowId] = useState('')
    const [dispositionId, setDispositionId] = useState('')
    const [dispositionValue, setDispositionValue] = useState('')
    const [totalRecords, setTotalRecords] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sortBy, setSortBy] = useState("");
    const [orderBy, setOrderBy] = useState("");
    const [searchText, setSearchText] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [base64Image, setBase64Image] = useState("");
    const [errors, setErrors] = useState({});

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const brandToggle = document.querySelector("#kt_aside_toggle.brand-toggle");

        if (brandToggle) {
            console.log("brandToggle found:", brandToggle);
            setIsActive(brandToggle.classList.contains("active"));
        } else {
            console.warn("Element not found: #kt_aside_toggle.brand-toggle");
        }
    }, []);


    const modalWidth = isActive ? "100%" : "60%";


    useEffect(() => {
        getLoanList();
        getDisposition();
        setshowLoanId(loanId);
        setshowId(id);
        const today = new Date(); // Get today's date
        setSelectedDate(today); // Set the default date
    }, [])

    const handleGotoPage = (page) => {
        if (page < 0 || page >= pageCount) {
            setErrorMessage(`No data to display`);
        } else {
            setErrorMessage("");
            setPageIndex(page);
        }
    };

    const handleSort = (column) => {
        let newOrderBy = "asc";

        if (sortBy === column.id) {
            newOrderBy = orderBy === "asc" ? "desc" : "asc";
        }
        setSortBy(column.id);
        setOrderBy(newOrderBy);
        getHistoryList(pageIndex, pageSize, column.id, newOrderBy);
    };

    useEffect(() => {
        getHistoryList(pageIndex, pageSize, sortBy, orderBy);
    }, [pageIndex, pageSize, sortBy, orderBy]);

    const formatDate = (date) => {
        // Check if the input is null, undefined, or empty
        if (!date) {
            // console.error('Invalid or null date:', date);
            return ''; // Return an empty string for invalid inputs
        }

        const parsedDate = new Date(date);

        // Check if the parsed date is invalid
        if (isNaN(parsedDate.getTime())) {
            // console.error('Invalid date format:', date);
            return ''; // Return an empty string for malformed dates
        }

        const yy = parsedDate.getFullYear(); // Get the year (yyyy)
        const mm = (parsedDate.getMonth() + 1).toString().padStart(2, '0'); // Month with leading zero
        const dd = parsedDate.getDate().toString().padStart(2, '0'); // Day with leading zero

        return `${yy}-${mm}-${dd}`; // Return formatted date as yyyy-mm-dd
    };



    const languageOptions = [
        { value: "English", label: "English" },
        { value: "Hindi", label: "Hindi" },
        { value: "Marathi", label: "Marathi" },
        { value: "Tamil", label: "Tamil" },
        { value: "Spanish", label: "Spanish" },
        { value: "French", label: "French" },
        { value: "German", label: "German" },
        { value: "Chinese", label: "Chinese" },
    ];

    const defaultLanguage = languageOptions.find(option => option.value === "Hindi");
    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);

    // Options for payment delay reasons
    const delayReasonOptions = [
        { value: "financial_issues", label: "Financial Issues" },
        { value: "documentation_delay", label: "Documentation Delay" },
        { value: "approval_pending", label: "Approval Pending" },
        { value: "bank_processing", label: "Bank Processing" },
        { value: "other", label: "Other" },
    ];

    // Options for payment delay reasons
    const paymentModeOptions = [
        { value: "upi", label: "UPI" },
        { value: "cash", label: "Cash" },
        { value: "internet_banking", label: "Internet Banking" },
        { value: "cheque", label: "Cheque" },
        { value: "other", label: "Other" },
    ];

    const handleRadioChange = (event, val) => {
        console.log('Selected ID:', event.target.value); // Logs the value of the selected radio button
        setDispositionId(event.target.value)
        setDispositionValue(val.description)
        console.log('Selected Description:', val.description); // Logs additional details from the current item
        // Add your logic here (e.g., update state or call an API)
    };

    // Handle the change event
    const handleChange = (selectedOption) => {
        setSelectedLanguage(selectedOption);
    };

    const handleChangeReason = (selectedOption) => {
        setSelectedReason(selectedOption);
    };

    const handleChangePaymentMode = (selectedOption) => {
        setPaymentMode(selectedOption);
    };

    const handlePaymentAmountChange = (event) => {
        setPaymentAmount(event.target.value);
    };

    const handleUtrChange = (event) => {
        setUtr(event.target.value);
    };

    const handleTextChange = (event) => {
        setRemark(event.target.value); // Update state with the new value
        console.log("Text area value:", event.target.value); // Optional: Log the value
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith("image/")) {
            setSelectedImage(file);

            // Convert the image to Base64
            const reader = new FileReader();
            reader.onload = () => {
                // Remove the prefix (e.g., "data:image/png;base64,")
                const base64String = reader.result.split(",")[1];
                setBase64Image(base64String); // Store only the Base64 string
            };
            reader.readAsDataURL(file);
        } else {
            alert("Please upload a valid image file.");
            setSelectedImage(null);
            setBase64Image("");
        }
    };


    const getLoanList = async () => {
        try {
            const response = await getData(`loan/loanDetailsByLoanId?loanId=${loanId}&id=${id}`);
            console.log("response === ", response);
            const data = response?.data
            setData(data);

        } catch (error) {
            console.error('Error fetching data:', error);
            //   setErrorMessage('Error fetching data');
        } finally {
            //   setIsLoadingOn(false);
        }
    };

    const handleDownload = async () => {
        setIsLoadingOn(true);
        const tempData = await getData(`disposition/exportDispositionData?loanId=${loanId}`);
        setIsLoadingOn(false);
        if (tempData.statusCode === 200) {
            const downloadLink = document.createElement("a");
            downloadLink.href = tempData.data.Location;
            downloadLink.target = "_blank";
            downloadLink.rel = "noopener noreferrer";
            downloadLink.click();
        }
    }

    const getDisposition = async () => {

        try {
            const response = await getData(`disposition/dispositionDetails?attribute=DISPOSITION`);
            console.log("disposition response === ", response);

            const data = response?.data
            setDisposition(data);


        } catch (error) {
            console.error('Error fetching data:', error);
            //   setErrorMessage('Error fetching data');
        } finally {
            //   setIsLoadingOn(false);
        }
    }

    const closeModal = () => {
        setIsOpen(false);
    };

    const closeHistoryModal = () => {
        setHistoryModalIsOpen(false);
    }

    const callHostory = () => {
        setHistoryModalIsOpen(true);
    }

    const callDisposition = () => {
        setIsOpen(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationErrors = {};
        if (!dispositionValue) {
            customSwal.fire({
                icon: "error",
                title: "Error!",
                text: "Select Disposition"
            })
            return;
        }

        // Check if Promise Date is required based on disposition value
        if (['PTP', 'FPTP', 'LM', 'CB', 'BPTP', 'Field PTP'].includes(dispositionValue) && !promiseDate) {
            validationErrors.promiseDate = "Promise Date is required";
        }

        // Validate fields for 'PAID' or 'PARTIAL PAID' dispositions
        if (['PAID', 'PARTIAL PAID'].includes(dispositionValue)) {
            if (!paymentDate) validationErrors.paymentDate = "Payment Date is required";
            if (!paymentMode) validationErrors.paymentMode = "Payment Mode is required";
            if (!paymentAmount) validationErrors.paymentAmount = "Payment Amount is required";
            if (!utr) validationErrors.utr = "UTR is required";
            if (!base64Image) validationErrors.base64Image = "File is required";
        }

        // Check if there are validation errors
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);

            // Scroll to the first error field
            if (validationErrors.promiseDate) {
                document.getElementById("promiseDate").scrollIntoView({ behavior: "smooth", block: "center" });
            } else if (validationErrors.paymentDate) {
                document.getElementById("paymentDate").scrollIntoView({ behavior: "smooth", block: "center" });
            } else if (validationErrors.paymentMode) {
                document.getElementById("paymentMode").scrollIntoView({ behavior: "smooth", block: "center" });
            } else if (validationErrors.paymentAmount) {
                document.getElementById("paymentAmount").scrollIntoView({ behavior: "smooth", block: "center" });
            } else if (validationErrors.utr) {
                document.getElementById("utr").scrollIntoView({ behavior: "smooth", block: "center" });
            } else if (validationErrors.base64Image) {
                document.getElementById("image-upload").scrollIntoView({ behavior: "smooth", block: "center" });
            }
            // You can add additional conditions here for other fields if needed
            return;
        }

        // if (!base64Image) {
        //     customSwal.fire({
        //         icon: "error",
        //         title: "Error!",
        //         text: "Please upload image"
        //     })
        //     return;
        // }

        const formattedDate = formatDate(selectedDate);
        const formattedPromiceDate = formatDate(promiseDate);

        let filterData = {
            id: Number(showId),
            loanId: showLoanId,
            dispositionId: dispositionId,
            dispositionCode: dispositionValue,
            dispositionDate: formattedDate,
            promiseDate: formattedPromiceDate,
            dispositionStatus: "OPEN" || "",
            dispositionFrom: "FOS",
            remark: remark,
            utr: utr,
            language: selectedLanguage.value || "",
            dispositionReason: selectedReason.value || "",
            attachment1: base64Image,
            paymentDate: paymentDate || "",
            paymentMode: paymentMode.value || "",
            paymentAmount: paymentAmount || "",
        };
        const tempData = await postData("disposition/addDisposition", filterData);

        if (tempData.statusCode == 200) {
            customSwal.fire({
                icon: "success",
                title: "Success",
                text: tempData?.message
            })
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
        else {
            customSwal.fire({
                icon: "error",
                title: "Error!",
                text: tempData?.data?.message || "Something went wrong!!!"
            })
            // console.log(tempData, "tempData?.data?.message")
        }

    }

    function maskMobileNumber(mobileNumber) {
        // Check if mobileNumber is valid
        if (!mobileNumber) {
            // console.error('Invalid mobile number:', mobileNumber);
            return ''; // Return an empty string or a placeholder
        }

        // Convert the number to a string if it isn't already
        mobileNumber = mobileNumber.toString();

        // Mask all but the last three digits
        return mobileNumber.slice(0, -3).replace(/\d/g, 'X') + mobileNumber.slice(-3);
    }

    // Example usage
    const mobileNumber = undefined; // or null, or a valid number
    const maskedNumber = maskMobileNumber(mobileNumber);
    console.log(maskedNumber); // Output: (empty string or error message in console)

    function maskEmail(email) {
        // Check if email is valid
        if (!email || typeof email !== 'string' || !email.includes('@')) {
            // console.error('Invalid email:', email);
            return ''; // Return an empty string or a placeholder
        }

        // Split the email into username and domain
        const [username, domain] = email.split('@');

        // Ensure username exists
        if (!username) {
            console.error('Missing username:', email);
            return ''; // Return a placeholder
        }

        // Mask part of the username, leaving the first and last characters
        const maskedUsername = 'X'.repeat(username.length - 2);

        // Combine the masked username with the domain
        return `${maskedUsername}@${domain}`;
    }

    useEffect(() => {
        // Access the element by ID
        const element = document.getElementById('kt_aside_toggle');
        if (element) {
            // Get the class using className
            console.log('ClassName:', element.className);

            // Alternatively, you can use classList for detailed manipulation
            console.log('ClassList:', element.classList);
        } else {
            console.log('Element with ID "kt_aside_toggle" not found.');
        }
    }, []);

    useEffect(() => {
        getHistoryList()
    }, [])

    const getHistoryList = async () => {
        try {
            const currentPage = pageIndex + 1;
            let response = await getData(`disposition/dispositionHistory?loanId=${loanId}&page=${currentPage}&pageSize=${pageSize}&search=${searchText}&sortBy=${orderBy}&orderBy=${sortBy}`);
            const data = response?.data || []
            setTotalRecords(response?.pagination?.totalRecords)
            setPageCount(response?.pagination?.totalPages)
            setHistoryData(data);

        } catch (error) {
            console.error('Error fetching data:', error);
            //   setErrorMessage('Error fetching data');
        } finally {
            //   setIsLoadingOn(false);
        }
    };

    const columns = React.useMemo(
        () => [

            {
                Header: "Sr. No.",
                accessor: (row, i) => i + 1 + pageIndex * pageSize,
                Cell: ({ cell }) => (
                    <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
                ),
            },
            {
                Header: 'Disposition Code',
                accessor: 'dispositionCode'
            },
            {
                Header: 'Disposition Date',
                accessor: 'dispositionDate',
                Cell: ({ value }) => value ? formatDate(value) : '-'
            },
            {
                Header: 'promise Date',
                accessor: 'promiseDate',
                Cell: ({ value }) => value ? formatDate(value) : '-'
            },
            {
                Header: 'Payment Date',
                accessor: 'paymentDate',
                Cell: ({ value }) => value ? formatDate(value) : '-'
            },
            {
                Header: 'Image',
                accessor: 'attachment1',
                Cell: ({ value }) => {
                    const [isModalOpen, setIsModalOpen] = React.useState(false);        
                    if (!value) return '-';
                    const openModal = () => setIsModalOpen(true);
                    const closeModal = () => setIsModalOpen(false);
                    return (
                        <>
                            <img
                                src={value} 
                                alt="Attachment Preview" 
                                style={{ width: '50px', height: '50px', objectFit: 'cover', cursor: 'pointer' }}
                                onClick={openModal}
                            />
                            {isModalOpen && (
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: 0,
                                        left: 0,
                                        width: '100vw',
                                        height: '100vh',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dim background
                                        zIndex: 1000,
                                    }}
                                    onClick={closeModal}
                                >
                                    <div
                                        style={{
                                            position: 'relative',
                                            width: '80vw',
                                            height: '80vh',
                                            maxWidth: '600px',
                                            maxHeight: '600px',
                                            background: '#fff',
                                            borderRadius: '8px',
                                            overflow: 'hidden',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            padding: '0',
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <button
                                            onClick={closeModal}
                                            style={{
                                                position: 'absolute',
                                                top: '0',
                                                right: '0',
                                                background: 'none',
                                                border: 'none',
                                                fontSize: '1.5rem',
                                                cursor: 'pointer',
                                                color: '#000',
                                            }}
                                        >
                                            &times;
                                        </button>
                                        <img
                                            src={value}
                                            alt="Full-Size Image"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                Header: 'Remark',
                accessor: 'remark'
            }
        ],
        []
    )


    return (
        <div className="logs-card-div">
            {/* <Loader /> */}
            <Card className="loanInfoCard loaninfocenter">
                {/* <CardHeader title="Loan Information"></CardHeader> */}
                <Card className="col-12">
                <CardHeader className="col-4 d-flex justify-content-center align-items-center position-relative">
                        <h4 className="m-0">Loan Information</h4>
                    </CardHeader>
                </Card>
                <CardBody className="p-4 loanDetailsSec">
                    <div className="row">
                        <div className="col-sm-3">
                            <h6>Personal Details</h6>

                            <div className="cardSec">
                                <div className="d-flex">
                                    <div className="label">Shop Owner Name:</div>
                                    <div className="value">{data?.personal_information?.fullName ? data?.personal_information?.fullName : "-"}</div>
                                </div>
                                <div className="d-flex">
                                    <div className="label">Shop Name:</div>
                                    <div className="value">{data?.personal_information?.shopName ? data?.personal_information?.shopName : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Mobile Number:</div>
                                    <div className="value">{maskMobileNumber(data?.personal_information?.mobile) ? maskMobileNumber(data?.personal_information?.mobile) : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Email:</div>
                                    <div className="value">{maskEmail(data?.personal_information?.email) ? maskEmail(data?.personal_information?.email) : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Address:</div>
                                    <div className="value">{data?.personal_information?.address ? data?.personal_information?.address : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">City:</div>
                                    <div className="value">{data?.personal_information?.city ? data?.personal_information?.city : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Age:</div>
                                    <div className="value">{data?.personal_information?.age ? data?.personal_information?.age : '-'}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Language Preferred:</div>
                                    <div className="value">{data?.personal_information?.languagePreffered ? data?.personal_information?.languagePreffered : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">EMI Type:</div>
                                    <div className="value">{data?.personal_information?.loanType ? data?.personal_information?.loanType : "-"}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <h6>Product Information</h6>
                            <div className="cardSec">
                                <div className="d-flex">
                                    <div className="label">Last Disposition:</div>
                                    <div className="value">{data?.product_information?.lastDesposition ? formatDate(data?.product_information?.lastDesposition) : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Next PTP Datetime:</div>
                                    <div className="value">{data?.product_information?.nextPTPDateTime ? formatDate(data?.product_information?.nextPTPDateTime) : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Product:</div>
                                    <div className="value">{data?.product_information?.product ? data?.product_information?.product : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Product Tenure:</div>
                                    <div className="value">{data?.product_information?.loanTenure ? data?.product_information?.loanTenure : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Power Loan Onboarding Channel:</div>
                                    <div className="value">{data?.product_information?.powerLoanOnboardingChannel ? data?.product_information?.powerLoanOnboardingChannel : "-"}</div>
                                </div>
                                {/* <div className="d-flex mt-2">
                                    <div className="label">Is Power Loan Repeat:</div>
                                    <div className="value">{data?.product_information?.isPowerLoanReapeat ? data?.product_information?.isPowerLoanReapeat : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">No of power loans:</div>
                                    <div className="value">{data?.product_information?.numberOfPowerLoan ? data?.product_information?.numberOfPowerLoan : "-"}</div>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <h6>Loan Details</h6>
                            <div className="cardSec">
                                <div className="d-flex mt-2">
                                    <div className="label">Loan Amount:</div>
                                    <div className="value">
                                        {data?.loan_Details?.loanAmount ? (
                                            <>
                                                <MdCurrencyRupee style={{ fontSize: 14 }} />{data?.loan_Details?.loanAmount}
                                            </>
                                        ) : (
                                            "-"
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Total Outstanding:</div>
                                    <div className="value">
                                        {data?.loan_Details?.principalOutStanding ? (
                                            <>
                                                <MdCurrencyRupee style={{ fontSize: 14 }} />{data?.loan_Details?.principalOutStanding}
                                            </>
                                        ) : (
                                            "-"
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Total Penalty Fees:</div>
                                    <div className="value">
                                        {data?.loan_Details?.penalty ? (
                                            <>
                                                <MdCurrencyRupee style={{ fontSize: 14 }} />{data?.loan_Details?.penalty}
                                            </>
                                        ) : (
                                            "-"
                                        )}
                                    </div>

                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Total amount Paid:</div>
                                    <div className="value">
                                        {data?.loan_Details?.totalEMIAmountPaid ? (
                                            <>
                                                <MdCurrencyRupee style={{ fontSize: 14 }} />{data?.loan_Details?.totalEMIAmountPaid}
                                            </>
                                        ) : (
                                            "-"
                                        )}
                                    </div>

                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Total EMI Count:</div>
                                    <div className="value">
                                        {data?.loan_Details?.totalEMICount ? (
                                            <>
                                                <MdCurrencyRupee style={{ fontSize: 14 }} />{data?.loan_Details?.totalEMICount}
                                            </>
                                        ) : (
                                            "-"
                                        )}
                                    </div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Total Settlement Amount:</div>
                                    <div className="value">
                                        {data?.loan_Details?.totalSettlementAmount ? (
                                            <>
                                                <MdCurrencyRupee style={{ fontSize: 14 }} />{data?.loan_Details?.totalSettlementAmount}
                                            </>
                                        ) : (
                                            "-"
                                        )}
                                    </div>
                                </div>

                                <div className="d-flex mt-2">
                                    <div className="label">EMI start Date:</div>
                                    <div className="value">{formatDate(data?.loan_Details?.emiStartDate) ? formatDate(data?.loan_Details?.emiStartDate) : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">EMI Due Date:</div>
                                    <div className="value">{formatDate(data?.loan_Details?.emiDueDate) ? formatDate(data?.loan_Details?.emiDueDate) : "-"}</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <h6>More Details</h6>
                            <div className="cardSec">
                                <div className="d-flex">
                                    <div className="label">Address 1:</div>
                                    <div className="value">{data?.more_details?.address1 ? data?.more_details?.address1 : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Address 2:</div>
                                    <div className="value">{data?.more_details?.address2 ? data?.more_details?.address2 : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Address Type:</div>
                                    <div className="value">{data?.more_details?.addressType ? data?.more_details?.addressType : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Allocation Date:</div>
                                    <div className="value">{formatDate(data?.more_details?.allocationDate) ? formatDate(data?.more_details?.allocationDate) : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">Customer Status:</div>
                                    <div className="value">{data?.more_details?.customerStatus ? data?.more_details?.customerStatus : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">landmark:</div>
                                    <div className="value">{data?.more_details?.landmark ? data?.more_details?.landmark : "-"}</div>
                                </div>
                                <div className="d-flex mt-2">
                                    <div className="label">mobile1:</div>
                                    <div className="value">{data?.more_details?.mobile1 ? data?.more_details?.mobile1 : "-"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-sm-2">
                            <h6>Send Payment via</h6>
                            <div className="d-flex">
                                <div><MdOutlinePayments style={{ fontSize: 22 }} /></div>
                                <div className="ml-4"><FcGoogle style={{ fontSize: 20 }} /></div>
                                <div className="ml-4"><IoIosLink style={{ fontSize: 20 }} /></div>
                            </div>
                        </div>
                        <div className="col-sm-10">
                            <h6>Other Actions</h6>
                            <div className="d-flex">
                                <div><button className="btn btn-outline-primary" onClick={callHostory}>Call & Visit History</button></div>
                                <div className="ml-5"><button className="btn btn-outline-primary" onClick={callDisposition}>Add Call Disposition</button></div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" },
                    content: {
                        margin: "auto",
                        padding: "0px 20px 20px 20px",
                        maxWidth: "60%",
                        maxHeight: "550px",
                        borderRadius: "10px",
                        border: "1px solid #ccc",
                        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)",
                    },
                }}
                ariaHideApp={false}
                contentLabel="Run Campaign Modal"
            >

                <div className="modalBody launchCampainModabody dispositionpopup">
                    <form onSubmit={handleSubmit}>
                        <div
                            style={{
                                display: "flex",
                                alignItem: "center",
                                position: 'sticky',
                                top: 0,
                                padding: '10px 0',
                                textAlign: 'center',
                                zIndex: '1000',
                                alignItems: 'center',
                                backgroundColor: '#fff',
                                borderBottom: "1px solid #EEF0F8"
                            }}
                        >
                            <h5>Add Collection Disposition</h5>
                            <div className="ml-auto mr-5">
                                <button type="submit" className="btn btn-primary mr-5">Save Disposition</button>
                            </div>
                            <button
                                className="btn btn-link modalCloseBtn"
                                style={{ position: "absolute", top: "10px", right: "-15px", fontSize: "1.5rem", lineHeight: "1", color: "#007bff" }}
                                onClick={closeModal}
                            >
                                <FaRegTimesCircle />
                            </button>
                        </div>

                        <div className="section01 mt-3">
                            <div className="disSec">
                                <div className="disHd">Non Connect Dispositions</div>
                                <div className="row">
                                    {
                                        disposition?.nonConnect?.map((val, ind) => {
                                            return <div key={ind} className="col-sm-3 mb-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="nonConnect" id={val.id} value={val.id} onChange={(e) => handleRadioChange(e, val)} />
                                                    <label className="form-check-label" htmlFor={val.id}>
                                                        {val?.code}
                                                    </label>
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                            <div className="disSec  mt-4">
                                <div className="disHd">Positive Dispositions</div>
                                <div className="row">
                                    {
                                        disposition?.positive?.map((val, ind) => {
                                            return <div key={val.id} className="col-sm-3 mb-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="nonConnect" id={val.id} value={val.id} onChange={(e) => handleRadioChange(e, val)} />
                                                    <label className="form-check-label" htmlFor={val.id}>
                                                        {val?.code}
                                                    </label>
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                            <div className="disSec mt-3">
                                <div className="disHd">Negative Dispositions</div>
                                <div className="row">
                                    {
                                        disposition?.negative?.map((val, ind) => {
                                            return <div key={val.id} className="col-sm-3 mb-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="nonConnect" id={val.id} value={val.id} onChange={(e) => handleRadioChange(e, val)} />
                                                    <label className="form-check-label" htmlFor={val.id}>
                                                        {val?.code}
                                                    </label>
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                            <div className="disSec  mt-4">
                                <div className="disHd">Other Dispositions</div>
                                <div className="row">
                                    {
                                        disposition?.other?.map((val, ind) => {
                                            return <div key={val.id} className="col-sm-3 mb-3">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="radio" name="nonConnect" id={val.id} value={val.id} onChange={(e) => handleRadioChange(e, val)} />
                                                    <label className="form-check-label" htmlFor={val.id}>
                                                        {val?.code}
                                                    </label>
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>
                            </div>
                        </div>

                        <div className="section02 mt-5">
                            <hr />
                            <h6>Other Details</h6>

                            <div className="row">
                                {/* <div className="col-sm-4">
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)} // Update state on date change
                                        dateFormat="yyyy-MM-dd" // Format the displayed date
                                        isClearable // Optional: Adds a clear button
                                        className="form-control"
                                    />
                                </div> */}
                                <div className="col-sm-4">
                                    <label htmlFor="promiseDate" className="form-label">Promise Date :</label>
                                    <DatePicker
                                        selected={promiseDate}
                                        name="promiseDate"
                                        onChange={(date) => {
                                            setPromiseDate(date);
                                            setErrors((prevErrors) => ({ ...prevErrors, promiseDate: "" })); // Clear error on change
                                        }}
                                        dateFormat="yyyy-MM-dd" // Format the displayed date
                                        isClearable={!promiseDate} // Optional: Adds a clear button
                                        className={`form-control ${errors?.promiseDate ? 'is-invalid' : ''}`} // Apply 'is-invalid' class if error exists
                                        placeholderText="Select Promise Date" // Adds a placeholder
                                        minDate={new Date()} // Restrict selection of past dates
                                        id="promiseDate"
                                    />
                                    {errors?.promiseDate && (
                                        <div className="text-danger">{errors.promiseDate}</div>
                                    )}
                                </div>
                                <div className="col-sm-4">
                                <label htmlFor="language" className="form-label">Language :</label>
                                    <Select
                                        options={languageOptions}
                                        value={selectedLanguage}
                                        onChange={handleChange}
                                        placeholder="Choose a language"
                                        isClearable // Optional: Allows clearing the selection
                                        id="language"
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="reason" className="form-label">Reason :</label>
                                    <Select
                                        options={delayReasonOptions}
                                        value={selectedReason}
                                        onChange={handleChangeReason}
                                        placeholder="Select a reason"
                                        isClearable // Optional: Allow clearing the selection
                                        id="reason"
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-4">
                                    <label htmlFor="paymentDate" className="form-label">Payment Date :</label>
                                    <DatePicker
                                        selected={paymentDate}
                                        name="paymentDate"
                                        onChange={(date) => {
                                            setPaymentDate(date);
                                            setErrors((prevErrors) => ({ ...prevErrors, paymentDate: "" })); // Clear error on change
                                        }}
                                        dateFormat="yyyy-MM-dd" // Format the displayed date
                                        isClearable={!paymentDate} // Optional: Adds a clear button
                                        className={`form-control ${errors?.paymentDate ? 'is-invalid' : ''}`} // Apply 'is-invalid' class if error exists
                                        placeholderText="Select Payment Date" // Adds a placeholder
                                        minDate={new Date()} // Restrict selection of past dates
                                        id="paymentDate"
                                    />
                                    {errors?.paymentDate && (
                                        <div className="text-danger">{errors.paymentDate}</div>
                                    )}
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="paymentMode" className="form-label">Payment Mode :</label>
                                    <Select
                                        options={paymentModeOptions}
                                        value={paymentMode}
                                        // onChange={handleChangePaymentMode}
                                        onChange={(selectedOption) => {
                                            handleChangePaymentMode(selectedOption); // Call the function with the selected option
                                            setErrors((prevErrors) => ({ ...prevErrors, paymentMode: "" })); // Clear error on change
                                        }}
                                        placeholder="Select a Payment Mode"
                                        className={` ${errors?.paymentMode ? 'is-invalid' : ''}`} // Apply 'is-invalid' class if error exists
                                        isClearable
                                        id="paymentMode"
                                    />
                                    {errors?.paymentMode && (
                                        <div className="text-danger">{errors.paymentMode}</div>
                                    )}
                                </div>
                                <div className="col-sm-4">
                                    <label htmlFor="paymentAmount" className="form-label">Payment Amount :</label>                                    
                                    <input
                                        type="number"
                                        step="any"
                                        placeholder="Enter Payment Amount"
                                        className={`form-control ${errors?.paymentAmount ? 'is-invalid' : ''}`}
                                        value={paymentAmount}
                                        // onChange={handlePaymentAmountChange}
                                        onChange={(paymentAmount) => {
                                            handlePaymentAmountChange(paymentAmount); // Call the function with the selected option
                                            setErrors((prevErrors) => ({ ...prevErrors, paymentAmount: "" })); // Clear error on change
                                        }}
                                        // isClearable
                                        id="paymentAmount"
                                    />
                                    {errors?.paymentAmount && (
                                        <div className="text-danger">{errors.paymentAmount}</div>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-sm-4">
                                    <label htmlFor="utr" className="form-label">UTR :</label>
                                    <input
                                        type="text"
                                        placeholder="Enter UTR"
                                        className={`form-control ${errors?.utr ? 'is-invalid' : ''}`}
                                        value={utr}
                                        // onChange={handleUtrChange}
                                        onChange={(utr) => {
                                            handleUtrChange(utr);
                                            setErrors((prevErrors) => ({ ...prevErrors, utr: "" }));
                                        }}
                                        // isClearable
                                        id="utr"
                                    />
                                    {errors?.utr && (
                                        <div className="text-danger">{errors.utr}</div>
                                    )}
                                </div>
                                <div className="col-sm-4">
                                    <div>
                                        <label htmlFor="image-upload">Upload Image :</label>
                                        <input
                                            type="file"
                                            id="image-upload"
                                            accept="image/*" // Restrict to image files
                                            onChange={handleFileChange}
                                        />
                                    </div>
                                    {errors?.base64Image && (
                                        <div className="text-danger">{errors.base64Image}</div>
                                    )}
                                </div>
                            </div>
                            <div className="row mt-3">

                                <div className="col-sm-12">
                                    <textarea className="form-control" cols={2} rows={5} placeholder="Add Remark" value={remark} onChange={handleTextChange}></textarea>
                                </div>
                            </div>


                        </div>
                    </form>
                </div>
            </ReactModal>

            <ReactModal
                isOpen={historyModalIsOpen}
                onRequestClose={closeHistoryModal}
                style={{
                    overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" },
                    content: {
                        margin: "auto",
                        padding: "20px",
                        maxWidth: modalWidth,
                        maxHeight: "fit-content",
                        height: '580px',
                        borderRadius: "10px",
                        border: "1px solid #ccc",
                        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)",
                        left: '6%',
                        right: '6%',
                        top: '10%'
                    },
                }}
                ariaHideApp={false}
                contentLabel="Run Campaign Modal"
            >
                <button
                    className="btn btn-link modalCloseBtn"
                    style={{ position: "absolute", top: "10px", right: "10px", fontSize: "1.5rem", lineHeight: "1", color: "#007bff" }}
                    onClick={closeHistoryModal}
                >
                    <FaRegTimesCircle />
                </button>
                <div className="modalBody launchCampainModabody dispositionpopup">
                    <h5 className="mb-3">Call & Visit History</h5>
                    <button
                            className="btn btn-link modalCloseBtn"
                            style={{ position: "absolute", top: "15px", right: "50px", fontSize: "1.5rem", lineHeight: "1", color: "#007bff" }}
                            onClick={handleDownload}
                        >
                            <FaFileDownload />
                    </button>
                    {historyData && historyData.length > 0 ? (
                        <div className="table-responsive historyTable">
                            <Table
                                columns={columns}
                                data={historyData}
                                pageCount={pageCount}
                                pageIndex={pageIndex}
                                pageSize={pageSize}
                                setPageIndex={setPageIndex}
                                setPageSize={setPageSize}
                                totalRecords={totalRecords}
                                handleGotoPage={handleGotoPage}
                                errorMessage={errorMessage}
                                handleSort={handleSort}
                                sortBy={sortBy}
                                orderBy={orderBy}
                            />
                        </div>
                    ) : (
                        <div className="no-data-message">No history data available.</div>
                    )}
                </div>
            </ReactModal>
        </div>
    );
}
export default LoanDetails;
// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
}) {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 200);

    return (
        <span>
            Search:{" "}
            <input
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
                placeholder={`${count} records...`}
                className="searchFilter"
            />
        </span>
    );
}

// Define a default UI for filtering
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length;

    return (
        <div>
            <input
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
                }}
                // placeholder={`Search ${count} records...`}
                className="searchFilter"
            />
            <i className="flaticon-search searchIcon"></i>
        </div>
    );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach((row) => {
            options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);

    // Render a multi-select box
    return (
        <select
            value={filterValue}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
        >
            <option value="">All</option>
            {options.map((option, i) => (
                <option key={i} value={option}>
                    {option}
                </option>
            ))}
        </select>
    );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
    column: { filterValue, setFilter, preFilteredRows, id },
}) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <>
            <input
                type="range"
                min={min}
                max={max}
                value={filterValue || min}
                onChange={(e) => {
                    setFilter(parseInt(e.target.value, 10));
                }}
            />
            <button onClick={() => setFilter(undefined)}>Off</button>
        </>
    );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
        preFilteredRows.forEach((row) => {
            min = Math.min(row.values[id], min);
            max = Math.max(row.values[id], max);
        });
        return [min, max];
    }, [id, preFilteredRows]);

    return (
        <div
            style={{
                display: "flex",
            }}
        >
            <input
                value={filterValue[0] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        val ? parseInt(val, 10) : undefined,
                        old[1],
                    ]);
                }}
                placeholder={`Min (${min})`}
                style={{
                    width: "70px",
                    marginRight: "0.5rem",
                }}
            />
            to
            <input
                value={filterValue[1] || ""}
                type="number"
                onChange={(e) => {
                    const val = e.target.value;
                    setFilter((old = []) => [
                        old[0],
                        val ? parseInt(val, 10) : undefined,
                    ]);
                }}
                placeholder={`Max (${max})`}
                style={{
                    width: "70px",
                    marginLeft: "0.5rem",
                }}
            />
        </div>
    );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;


// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue >= filterValue;
    });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";