import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../_metronic/_partials/controls";
import Loader from "../Loader/Loader";
import * as XLSX from 'xlsx';
import Alert from 'react-bootstrap/Alert';
import sampleCSVFile from "../../modules/DataImport/files/CampaignSampleFile.csv";
import { useHistory } from "react-router-dom";
import { useTable, useFilters, useGlobalFilter, usePagination, useAsyncDebounce, useSortBy } from 'react-table';
import { getData } from "../../store/utils.js";
import matchSorter from 'match-sorter'
import { useDebounce } from 'use-debounce';
import Table from "../ReactTable"
import { Parser } from "json2csv";
import { processOnData } from "../DataImport/DataImportProcess";
import { loanTable } from '../TableConstants/tableConstant';
import { FaFileDownload, FaRegTimesCircle } from "react-icons/fa";
import ReactModal from "react-modal";
function LoanList() {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchValue] = useDebounce(searchText, 1000);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loanId, setLoanId] = useState('');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [FileNameData, setFileNameData] = useState(null);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState(null);
  const [successAlertMsg, setSuccessAlertMsg] = useState(null);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [columns1, setColumns] = useState([]);
  let mandatoryYes = [], invalidCSV = false, errorMsg = 'CSV file does not contain proper record , please refer sample csv file.';
  let list2;
  useEffect(() => {
    getLoanList()
  }, [searchValue])
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);
  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
      setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    let newOrderBy = "asc";
  
  if (sortBy === column.id) {
    newOrderBy = orderBy === "asc" ? "desc" : "asc";
  }
  setSortBy(column.id);
  setOrderBy(newOrderBy);
  getLoanList(pageIndex, pageSize, column.id, newOrderBy);
};

useEffect(() => {
  getLoanList(pageIndex, pageSize, sortBy, orderBy);
}, [pageIndex, pageSize, sortBy, orderBy]);

  const getLoanList = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {

    try {
      const currentPage = pageIndex + 1;
      let response = await getData(`loan/getLoanList?page=${currentPage}&pageSize=${pageSize}&search=${searchText}&sortBy=${orderBy}&orderBy=${sortBy}`);

      const fetchedData = response?.data || []
      setData(fetchedData);
      setTotalRecords(response?.pagination?.totalRecords)
      setPageCount(response?.pagination?.totalPages)
      

    } catch (error) {
      console.error('Error fetching data:', error);
      //   setErrorMessage('Error fetching data');
    } finally {
      //   setIsLoadingOn(false);
    }
  };

  const showLoanDetails = (loanId, id) => {
    history.push({
      pathname: `/loan/LoanDetails`,
      search: `?loanId=${loanId}&id=${id}`, // Adding the loanId as a query parameter
    });
  }

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Loan Id',
        accessor: 'loanId',
        Cell: ({ cell }) => {
          return <>
            <div className="loanIdDownloadBtnDiv">
              <button
                className="btn btn-sm btn-clean view-log-detail"
                title="View details"
                value={cell.row.original.loanId}
                onClick={() => showLoanDetails(cell.row.original.loanId, cell.row.original.id)}
              ><b>{cell.row.original.loanId}</b>
              </button>
            </div>
          </>
        }
      },
      {
        Header: 'Shop Name',
        accessor: 'shopName',
      },
      {
        Header: 'Shop Owner Name',
        accessor: 'fullName',
      },
      {
        Header: 'Mobile Number',
        accessor: 'mobile'
      },
      {
        Header: 'EMI Due Date',
        accessor: 'emiDueDate'
      },
      // {
      //   Header: 'email',
      //   accessor: 'email'
      // }
    ],
    []
  )

  const history = useHistory();
  function displayLoanInfo(loanId) {
    console.log('onclick called:', loanId);
    if (loanId) {
      history.push({
        pathname: '/loan/details',
        loanId: loanId
      });
    }
  }

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )

  let sampleTemplate = [], myData = [];
  let CSVData = null;
  for (const iterator of loanTable) {
      sampleTemplate.push(iterator.csvColumnName);
  }
  if (sampleTemplate) {
    const fields = sampleTemplate;
    const opts = { fields };

    try {
        const parser = new Parser(opts);
        const csv = parser.parse(myData);
        CSVData = sampleCSVFile;
    } catch (err) {
        console.error(err);
    }
  }
   // process CSV data
  const processData = dataString => {
      const dataStringLines = dataString.split(/\r\n|\n/);
      const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
      let list = processOnData(dataString);
      // prepare columns list from headers
      const columns1 = headers.map(c => ({
          name: c,
          selector: c,
      }));
      list2 = JSON.parse(JSON.stringify(list));
      for (let i in list2) {
          delete list2[i].id;
      }
      //mandatory column added in array variable
      for (const iterator of loanTable) {
          if (iterator.mandatory == 'Y') {
              mandatoryYes.push(iterator.csvColumnName);
          }
      }
      // Expect input as d/m/y
      function isValidDate(s) {
          var bits = s.split('/');
          var d = new Date(bits[2], bits[1] - 1, bits[0]);
          return d && (d.getMonth() + 1) == bits[1];
      }

      //loop through check mandatory column and their values
      list2.forEach((element, index) => {

          if (element.agencyAllocationDate) {
              let isDate = isValidDate(element.agencyAllocationDate);
              console.log(" isDate -------- ", isDate);
              if (!isDate) {
                  errorMsg = `Missing or invalid data at Record #${index + 1}.`;
                  invalidCSV = true;
              }
          }

          mandatoryYes.forEach(mandatoryYesElement => {

              if (!element[mandatoryYesElement]) {
                  errorMsg = `Missing or invalid data at Record #${index + 1}.`;
                  invalidCSV = true;
              }
          });
      });

      //if valid csv then display in table otherwise throwing error
      if (!invalidCSV) {
          setData(list2);
          setDisplayError(false);
          setShowErrorAlert(false);
      }
      else {
          setData([]);
          setErrorAlertMsg(errorMsg);
          setShowErrorAlert(true);
      }
      setColumns(columns1);
  }
  
  const handleFileUpload = e => {
      console.log('data length:', data);
      const file = e.target.files[0];
      const nameOfFile = e.target.files[0].name;
      // console.log("filenamecsv-----------", nameOfFile);
      const reader = new FileReader();
      reader.onload = (evt) => {
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          processData(data);
      };
      reader.readAsBinaryString(file);
      setSelectedFile(e.target.files[0]);
      setFileNameData(nameOfFile)
      e.target.value = '';
  }

  const handleGetDataFromPanel = async () => {
      try {
          const response = await getData('loan/getDatafromPanelPortal');
          const data = response?.data;
          console.log("templateData === ", data);
      } catch (error) {
          console.error('Error fetching data:', error);
          setErrorMessage('Error fetching data');
      } finally {
          setIsLoadingOn(false);
      }
  }

  const handleDownload = async () => {
    try {
      const response = await getData('loan/exportLoanEmiDueFile');
      if (response.status === "success" && response.data) {
        const link = document.createElement('a');
        link.href = response.data; // Use the presigned URL from the API response
        link.download = 'Loan_Data_From_Panel.csv'; // Set the desired file name
        document.body.appendChild(link);
        link.click(); 
        document.body.removeChild(link); // Remove the temporary anchor element
      } else {
        console.error('Error: Unable to fetch file for download. Response: ', response.message);
        alert('Failed to download the file. Please try again later.');
      }
    } catch (error) {
      console.error('Error during file download: ', error);
      alert('An error occurred while downloading the file. Please try again later.');
    }
  };

  const handleDispositionExport = async () => {
        setIsLoadingOn(true);
        const tempData = await getData(`disposition/exportDispositionData?loanId=${loanId}&fromDate=${fromDate}&toDate=${toDate}`);
        setIsLoadingOn(false);
        closeModal();

        if (tempData.statusCode === 200) {
            const downloadLink = document.createElement("a");
            downloadLink.href = tempData.data.Location;
            downloadLink.target = "_blank";
            downloadLink.rel = "noopener noreferrer";
            downloadLink.click();
            setLoanId('');
            setFromDate('');
            setToDate('');
        }
  }
  
  return (
    <div className="row m-0 loanDataImport">
      {/* <Loader /> */}
      <Card className="col-12">
          <CardHeader className="col-4">
              {/* <div className="col-2">
                  <div>
                      <label className="csvLabel mb-0">
                          <input type="file"
                              accept=".csv"
                              onChange={handleFileUpload} required
                          />
                          <span className='btn btn-primary font-weight-bold transformBtn csvSpan'>
                              Select CSV</span>
                      </label>
                  </div>

              </div>
              <div className="col-6 d-flex align-items-center justify-content-center">
                  {showErrorAlert &&
                      <Alert variant="danger" onClose={() => setShowErrorAlert(false)} dismissible>
                          {errorAlertMsg}
                      </Alert>
                  }
                  {showSuccessAlert &&
                      <Alert className="custom-success-alert" onClose={() => setShowSuccessAlert(false)} dismissible>
                          {successAlertMsg}
                      </Alert>
                  }
              </div> */}
              <div className="col-9 justify-content-end align-items-center">

                  <button
                      id="loanData"
                      type="button"
                      className="settingBtn font-weight-bold download-csv transformBtn"
                      onClick={()=>handleGetDataFromPanel()}
                  >
                      <span className='btn btn-primary font-weight-bold transformBtn csvSpan'>
                          Fetch Data
                      </span>
                  </button>
              </div>
              <div className="col-2 justify-content-end align-items-center">

                  <button
                      id="CSV"
                      type="button"
                      className="settingBtn font-weight-bold download-csv transformBtn"
                  >
                      <a href={sampleCSVFile} download="LoanTemplate.csv">Loan Template</a>
                  </button>
              </div>
              <div className="col-md-1 pl-0 text-center">
                  <button
                    onClick={handleDownload}
                    id="CSV"
                    type="button"
                    className="font-weight-bold download-csv transformBtn acceptDataCss"
                  >
                    <a href="javascript:void(0)">
                      <FaFileDownload style={{ width: '24px', height: '24px' }} />
                    </a>
                  </button>
              </div>
          </CardHeader>
      </Card>
      
      <Card className="table-responsive">
        <CardHeader >
          <h5 className="px-3 mt-5">Loan List </h5>
          <div className="col-11 d-flex">
            <div className="ml-auto mt-2" style={{ width: '300px' }}>
              <input type="text" className="form-control" placeholder="Search" onChange={handleSearch} value={searchText} />
            </div>
            <div className="ml-auto mt-2" style={{ width: '50px' }}>
                  <button
                      id="loanData"
                      type="button"
                      className="settingBtn font-weight-bold download-csv transformBtn"
                      onClick={openModal}
                  >
                  <a >
                    <FaFileDownload style={{ width: '24px', height: '24px' }}/>
                  </a>
                  </button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="p-4">
          {data && data.length > 0 ? (
            <Table
              columns={columns} 
              data={data} 
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageIndex={setPageIndex}
              setPageSize={setPageSize}
              totalRecords={totalRecords}
              handleGotoPage={handleGotoPage}
              errorMessage={errorMessage} 
              handleSort={handleSort}
              sortBy={sortBy}
              orderBy={orderBy}
            />
          ) : (
            <p>No data available</p>
          )}
        </CardBody>
      </Card>

      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.6)', zIndex: 100, },
          content: {
            margin: 'auto',
            padding: '20px',
            maxWidth: '400px',
            maxHeight: '380px',
            borderRadius: '10px',
            border: '1px solid #ccc',
            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.5)',
            zIndex: 100,
          },
        }}
        ariaHideApp={false}
        contentLabel="Export Disposition Modal"
      >
        <div className="modalHeader d-flex justify-content-between align-items-center mb-3">
          <h4 className="modalTitle mb-0">Export Disposition Data</h4>
          <button
            className="btn btn-link modalCloseBtn"
            style={{ position: 'absolute', cursor: 'pointer', top: '10px', right: '10px', fontSize: '1.5rem', lineHeight: '1', color: '#007bff' }}
            onClick={closeModal}
          >
            <FaRegTimesCircle />
          </button>
        </div>
        <div className="modalBody">
          <div className="form-group">
            <label>Loan ID</label>
            <input
              type="text"
              className="form-control mb-3"
              value={loanId}
              onChange={(e) => setLoanId(e.target.value)}
              placeholder="Enter Loan ID"
            />
          </div>
          <div className="form-group">
            <label>From Date</label>
            <input
              type="date"
              className="form-control mb-3"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>To Date</label>
            <input
              type="date"
              className="form-control mb-3"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div style={{ textAlign: 'center', marginTop: 20 }}>
            <button
              className="btn btn-primary font-weight-bold transformBtn"
              type="button"
              onClick={handleDispositionExport}
              disabled={isLoading}
            >
              {isLoading ? 'Exporting...' : 'Submit'}
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
export default LoanList;
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        className="searchFilter"
      />
    </span>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <div>
      <input
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value.toString().toLowerCase() || undefined); // Set undefined to remove the filter entirely
        }}
        // placeholder={`Search ${count} records...`}
        className="searchFilter"
      />
      <i className="flaticon-search searchIcon"></i>
    </div>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">All</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button onClick={() => setFilter(undefined)}>Off</button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  // return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;


// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";
