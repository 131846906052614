import axios from 'axios';
import AWS from 'aws-sdk';
import environment from './../../env.json';
import { dev } from '../utils/constant';
const apiHost = dev

export default async function getApiData(data) {
    return new Promise(async (resolve, reject) => {
        const headers = {
            'X-Api-Key': environment.apiKey,
            'Content-Type': 'application/json',
        }
        await axios.post(`${environment.baseURL}/api/v1/service`, data, {
            headers: headers
        })


            .then(function (response) {
                if (response &&
                    response.data &&
                    response.data.status &&
                    response.data.status === "success"
                ) {
                    const data = response.data.data;

                    AWS.config.update({
                        accessKeyId: data.accessKeyId,
                        secretAccessKey: data.secretAccessKey
                    });

                    resolve(data);
                }
                else {
                    reject(false);
                }

            })
            .catch(function (error) {
                reject(false);
            });
    });
};

export const postApiData = (data) => {
    return new Promise(async (resolve, reject) => {
        const headers = {
            'X-Api-Key': environment.apiKey,
            'Content-Type': 'application/json',
        }
        await axios.post(`${environment.baseURL}/api/v1/service`, data, {
            headers: headers
        })


            .then(function (response) {
                if (response &&
                    response.data &&
                    response.data.status &&
                    response.data.status === "success"
                ) {
                    resolve(response.data);
                }
                else {
                    reject(false);
                }

            })
            .catch(function (error) {
                reject(false);
            });
    });
};

export const postDocumentData = (data) => {
    return new Promise(async (resolve, reject) => {
        const headers = {
            'X-Api-Key': environment.apiKey,
            'Content-Type': 'application/json',
        }
        await axios.post(`${apiHost.baseURL}/users/userDocument`, data, {
            headers: headers
        })

            .then(function (response) {
                if (response &&
                    response.data &&
                    response.data.status &&
                    response.data.status === "success"
                ) {
                    resolve(response.data);
                }
                else {
                    reject(false);
                }

            })
            .catch(function (error) {
                reject(false);
            });
    });
};

export const klimoPos_data = async (api_url, requestOptions, Token) => {
    return new Promise(async (resolve, reject) => {
        const data = requestOptions;
        const headers = {
            'X-Api-Key': environment.apiKey,
            'Content-Type': 'application/json',
        }

        await axios.post(`https://gb5aew78zc.execute-api.ap-south-1.amazonaws.com/Dev/api/v1/${api_url}`, data, {
            headers: headers
        })
            .then(function (response) {
                if (response && response.data && response.data.data) {
                    resolve(response.data);
                }
                else {
                    resolve([]);
                }

            })
            .catch(function (error) {
                console.log(" reject =========== ", error);
                resolve([]);
                // reject(false);
            });
    });
}

export const customFileName = (file) => {
    const filename = file.replaceAll(/\s/g, '_');
    const lastDotIndex = filename.lastIndexOf(".");
    if (lastDotIndex > 0) {
        const otherPart = filename.slice(0, lastDotIndex) + "_t_" + Date.now();
        const fileExtension = filename.slice(lastDotIndex + 1);
        return otherPart + "." + fileExtension;
    } else {
        return file;
    }
}


export const verification_data = async (requestOptions, Token) => {
    return new Promise(async (resolve, reject) => {
        const data = requestOptions;
        console.log("dataconst----", data)
        const headers = {
            'X-Api-Key': environment.apiKey,
            'Content-Type': 'application/json',
        }

        await axios.post(`${environment.baseURLverification}/service`, data, {
            headers: headers
        })
            .then(function (response) {
                if (response && response.data) {
                    resolve(response.data);
                }
                else {
                    resolve([]);
                }

            })
            .catch(function (error) {
                console.log(" reject =========== ", error);
                resolve([]);
                // reject(false);
            });
    });
}
