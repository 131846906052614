import Swal from "sweetalert2";

// Create a reusable Swal instance with custom styles
const customSwal = Swal.mixin({
  customClass: {
    confirmButton: 'swal-confirm-btn',
    cancelButton: 'swal-cancel-btn',
  },
  buttonsStyling: false, // Allows control over button styles via classes
});

// Inject custom CSS globally
const style = document.createElement("style");
style.textContent = `
  .swal-confirm-btn {
    background-color: #00D464 !important;
    color: #fff !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    cursor: pointer !important;
  }
  .swal-cancel-btn {
    background-color: #f8f9fa !important;
    color: #333 !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    cursor: pointer !important;
  }
`;
document.head.appendChild(style);

// Export the customized Swal instance
export default customSwal;
