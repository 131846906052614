import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import BucketWisePerformaceTable from "./modules/Dashboard/pages/BucketWisePerformaceTable";
import CallerWiseMis from "./modules/Dashboard/pages/CallerWiseMis";
import AgencyWisePerformaceTable from "./modules/Dashboard/pages/AgencyWisePerformanceTable";
import FinancierWisePerformaceTable from "./modules/Dashboard/pages/FinancierWisePerformanceTable";
import Upload from "./modules/Legal/pages/Upload";
import GeneratedData from "./modules/Legal/pages/GeneratedData";
import Track from "./modules/Legal/pages/Track";
import UploadSegmentation from "./modules/EmailSegmentation/pages/UploadSegmentation";
import TrackEmail from "./modules/EmailSegmentation/pages/TrackEmail";
import TeleCaller from "./modules/Dashboard/components/TeleCaller/TeleCaller";
import Summary from "./modules/Dashboard/pages/Summary";
import Segmentation from "./modules/Dashboard/pages/Segmentation";
import AutomatedLegal from "./modules/Dashboard/pages/AutomatedLegal";
import BarChart from "./modules/Charts/pages/BarChart";
import Tempdashboard from "./modules/Dashboard/pages/TempDashboard";
import Charts from "./modules/Charts/Charts";
import CityWiseLoanCountTable from "./modules/Charts/pages/CityWiseLoanCountTable";
import FieldMisPerformanceTable from "./modules/Performance/Pages/FieldMisPerformanceTable";
import Dashboard_Attendance from "./modules/Attendance/Dashboard_Attendance";
import Accept_Reject from "./modules/Attendance/Accept_Reject";
import  User_Notification  from "./modules/Notifications/components/User_Notification";
import AprReports from "./modules/Dashboard/pages/AprReports";
import KlimoCustomerDetails from "./modules/Today/Details/pages/KlimoCustomerDetails";
import Biometric_Attendance from "./modules/Attendance/Biometric_Attendance";
import VerificationAllocation from "./modules/DataImport/subPages/Verification/VerificationAllocation";
import SmsCampaign from "./modules/Campaign/SmsCampaign";
import AddSmsCampaign from "./modules/Campaign/AddSmsCampaign";
import FieldData from "./modules/Field/FieldData";
import SelectSmsCampaign from "./modules/Campaign/SelectSmsCampaign";
import Campaign from "./modules/Campaign/Campaign.js";
import LoanList from "./modules/Loan/LoanList.js";
import LoanDetails from "./modules/Loan/LoanDetails.js";
import LaunchCampaign from "./modules/Campaign/LaunchCampaign.js";
// import LeaveApply from "./modules/LeaveApplication/LeaveApply";
// import LeaveApplication from "./modules/LeaveApplication/LeaveApplication";
// import leaveDashTeamTabs from "./modules/LeaveApplication/leaveDashTeamTabs";

const ChartsPage = lazy(() =>
  import("./modules/Charts/Charts")
);
const AgentActivitiesPage = lazy(() =>
  import("./modules/AgentActivities/AgentActivities")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const LoanPage = lazy(() =>
  import("./modules/Loan/Loan")
);
const LoanListPage = lazy(() =>
  import("./modules/Loan/LoanList")
);
const TodayPage = lazy(() =>
  import("./modules/Today/Today")
);
const BankDepositPage = lazy(() =>
  import("./modules/BankDeposits/BankDeposits")
);
const CollectionAgencyPage = lazy(() =>
  import("./modules/CollectionAgency/CollectionAgency")
);
const AgenciesInfo = lazy(() =>
  import("./modules/CollectionAgency/pages/AgenciesInfo")
);
const FinanciersPage = lazy(() =>
  import("./modules/Financiers/Financiers")
);
const FinanciersInfo = lazy(() =>
  import("./modules/Financiers/pages/FinanciersInfo")
);
const ContactsPage = lazy(() =>
  import("./modules/Contacts/Contacts")
);
const WebsitePage = lazy(() =>
  import("./modules/Website/Website")
);
const InterviewList = lazy(() =>
  import("./modules/Interview/InterviewList")
);
const DetailsPage = lazy(() =>
  import("./modules/Loan/Details/Details")
);
const TodayDetailsPage = lazy(() =>
  import("./modules/Today/Details/Details")
);

const TeleCallerDetailsPage = lazy(() =>
  import("./modules/Dashboard/components/TeleCaller/Details/Details")
);

const DataImportPage = lazy(() =>
  import("./modules/DataImport/DataImport")
);

const DataExportPage = lazy(() =>
  import("./modules/DataExport/DataExport")
);

const PaymentFile = lazy(() =>
  import("./modules/PaymentFile/PaymentFile")
);


const LoanDataPage = lazy(() =>
  import("./modules/DataImport/subPages/LoanData/LoanData")
);
const LoanDataForLegalPage = lazy(() =>
  import("./modules/DataImport/subPages/LoanDataForLegal/LoanDataForLegal")
);
const AprPage = lazy(() =>
  import("./modules/DataImport/subPages/APR/Apr")
);
const AprReportsPage = lazy(() =>
  import("./modules/Dashboard/pages/AprReports")
);
const UserDataPage = lazy(() =>
  import("./modules/DataImport/subPages/Users/Users")
);
const AllocationDataPage = lazy(() =>
  import("./modules/DataImport/subPages/AllocationData/AllocationData")
);
const DispositionPage = lazy(() =>
  import("./modules/DataImport/subPages/Disposition/Disposition")
);
const PaymentPage = lazy(() =>
  import("./modules/DataImport/subPages/Payment/Payment")
);
const PaidFile = lazy(() =>
  import("./modules/DataImport/subPages/PaidFile/PaidFile")
);
const FAQPage = lazy(() =>
  import("./modules/DataAnalytic/subPages/Faq")
);

const HelpPage = lazy(() =>
  import("./modules/Help/Help")
);

const NotificationPage = lazy(() =>
  import("./modules/Notifications/Notifications")
);

const DigitalCampaigns = lazy(() =>
  import("./modules/DigitalCampaigns/DigitalCampaigns")
);

const AcceptReject = lazy(() =>
  import("./modules/Attendance/Accept_Reject")
);
const BiometricAttendance = lazy(() =>
  import("./modules/Attendance/Biometric_Attendance")
);

const LeaveApplication = lazy(() =>
  import("./modules/LeaveApplication/LeaveApplication")
);
const LeaveApply = lazy(() =>
  import("./modules/LeaveApplication/LeaveApply")
);
const LeaveException = lazy(() =>
  import("./modules/LeaveApplication/LeaveException")
);
const LeaveApplyException = lazy(() =>
  import("./modules/LeaveApplication/LeaveApplyException")
);
const ExceptionApply = lazy(() =>
  import("./modules/LeaveApplication/ExceptionApply")
);
const leaveDashTeamTabs = lazy(() =>
  import("./modules/LeaveApplication/leaveDashTeamTabs")
);
// const leaveDashTeamTabs = lazy(() =>
//   import("./modules/LeaveApplication/leaveDashTeamTabs")
// );
const Verification_Allocation = lazy(() =>
  import("./modules/DataImport/subPages/Verification/VerificationAllocation")
);

const Search_CPV = lazy(() =>
  import("./modules/DataImport/subPages/Verification/Search_CPV")
);
const CPV_Data = lazy(() =>
  import("./modules/DataImport/subPages/Verification/CPV_Data")
);

const DashboardAttendance = lazy(() =>
  import("./modules/Attendance/Dashboard_Attendance")
);

const SkipModuleUploadData = lazy(() =>
  import("./modules/SkipModuleUploadData/SkipModuleUploadData")
);
const KlimoDetailsPage  = lazy(() =>
  import("./modules/Today/Details/pages/KlimoCustomerDetails")
);

const VerificationPage = lazy(() =>
  import("./modules/DataImport/subPages/Verification/Verification")
);
const CollectionTrendDays  = lazy(() =>
  import("./modules/CollectionTrendMonthDays/collectionTrendDays")
);

const CollectionTrendMonth = lazy(() =>
  import("./modules/CollectionTrendMonthDays/collectionTrendMonths")
);


export default function BasePage() {
  const user = useSelector(state => state.entities.auth.data);
  const [homeUrl, setHomeUrl] = useState();
  //console.log('base page data:', user);
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  let urls = window.location.href;
  let urlSplit = urls.split('/');
  // Taking last array value
  let getCurrentUrl = urlSplit.slice(-1).pop();
  console.log('check url:', getCurrentUrl);
  useEffect(()=>{
    if (user.role == 'Financier Manager') {
      setHomeUrl('/campaign/sms-campaign')
    }
    else if (user.role == 'Team Leader/Supervisor'  || user.role == 'Central Coordinator' || user.role == 'Manager' || user.role == 'Area Manager') {
      setHomeUrl('/campaign/sms-campaign')
    }
    else if( user.role == "HR"){
      setHomeUrl("/campaign/sms-campaign");
    }
    // else if( user.role == "Telephone Agent"){
    //   setHomeUrl("/campaign/sms-campaign");
    // }
    else {
      setHomeUrl('/campaign/sms-campaign')
    }
  },[user])
  if(homeUrl) {
    return (
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {/* Redirect from root URL to /dashboard. */}
          {user.role !== "Telephone Agent" ? (
            <Redirect exact from="/" to={homeUrl} />
          ) : (
            <Redirect exact from="/" to="/LeaveApplication" />
          )}
          <ContentRoute
            exact
            path="/dashboard/financier-status"
            component={DashboardPage}
          />
          {/* <ContentRoute path="/builder" component={BuilderPage} />
          <ContentRoute path="/my-page" component={MyPage} /> */}
          <Route
            path="/performance/bucket-wise-performance"
            component={BucketWisePerformaceTable}
          />
          <Route
            path="/performance/caller-wise-mis"
            component={CallerWiseMis}
          />
          <Route
            path="/performance/agency-wise-performance"
            component={AgencyWisePerformaceTable}
          />
          <Route path="/dashboard/loan-list" component={TeleCaller} />
          <Route path="/dashboard/summary" component={Summary} />
          <Route
            path="/Attendance/Dashboard_Attendance"
            component={DashboardAttendance}
          />
          <Route path="/Attendance/Accept_Reject" component={AcceptReject} />
          <Route
            path="/Attendance/Biometric_Attendance"
            component={BiometricAttendance}
          />
          <Route
            path="/Notifications/components/User_Notification"
            component={User_Notification}
          />
          <Route path="/dashboard/segmentation" component={Segmentation} />
          <Route path="/dashboard/autmated-legal" component={AutomatedLegal} />
          <Route
            path="/dashboard/financier-dashboard"
            component={Tempdashboard}
          />
          {user.role === "Agency Manager" &&
            getCurrentUrl === "financier-wise-performance" && (
              <Redirect
                exact
                from="/performance/financier-wise-performance"
                to="/dashboard/financier-status"
              />
            )}
          {user.role === "Telephone Agent" &&
            getCurrentUrl === "financier-status" && (
              <Redirect exact from="/LeaveApplication" to="/LeaveApplication" />
            )}
          {user.role !== "Agency Manager" && (
            // <Route path="/financier-wise-performance" component={FinancierWisePerformaceTable} />
            <Route
              path="/performance/financier-wise-performance"
              component={FinancierWisePerformaceTable}
            />
          )}
          <Route path="/performance/charts" component={Charts} />
          {user.role === "Agency Manager" &&
            getCurrentUrl === "financier-wise-performance" && (
              <Redirect
                exact
                from="/performance/manager-wise-performance"
                to="/dashboard/financier-status"
              />
            )}
          {user.role !== "Agency Manager" && (
            <Route
              path="/performance/manager-wise-performance"
              component={FinancierWisePerformaceTable}
            />
          )}
          <Route
            path="/performance/field-mis-performance"
            component={FieldMisPerformanceTable}
          />
          {user.role === "Agency Manager" &&
            getCurrentUrl === "geography-wise" && (
              <Redirect
                exact
                from="/performance/geography-wise"
                to="/dashboard/financier-status"
              />
            )}
          {user.role !== "Agency Manager" && (
            <Route
              path="/performance/geography-wise"
              component={CityWiseLoanCountTable}
            />
          )}
          {/* {user.role == 'Central Team' && ( */}
          <Route
            path="/dashboard/agent-activities"
            component={AgentActivitiesPage}
          />
          {/* )} */}
          <Route path="/digital-campaigns" component={DigitalCampaigns} />
          <Route
            path="/skip-module-upload-data"
            component={SkipModuleUploadData}
          />
          <Route
            path="/modules/Today/Details/pages/KlimoCustomerDetails"
            component={KlimoDetailsPage}
          />
          {/* <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Route path="/e-commerce" component={ECommercePage} /> */}
          <Route path="/user-profile" component={UserProfilepage} />
          <Route exact path="/loan" component={LoanPage} />
          <Route path="/loan/loanList" component={LoanList} />
          <Route path="/loan/loanDetails" component={LoanDetails} />
          {getCurrentUrl == "loan" && (
            <Redirect exact from="/loan" to="/dashboard/financier-status" />
          )}
          <Route exact path="/today" component={TodayPage} />
          <Route path="/bank-deposits" component={BankDepositPage} />
          <Route
            exact
            path="/overview/agency"
            component={CollectionAgencyPage}
          />
          <Route path="/agency/details" component={AgenciesInfo} />
          <Route path="/financier/details" component={FinanciersInfo} />
          {user.role == "Agency Manager" && getCurrentUrl == "financiers" && (
            <Redirect
              exact
              from="/overview/financier"
              to="/dashboard/financier-status"
            />
          )}
          {user.role !== "Agency Manager" && (
            <Route
              exact
              path="/overview/financier"
              component={FinanciersPage}
            />
          )}
          {user.role !== "Agency Manager" && (
            <Route exact path="/data-export" component={DataExportPage} />
          )}
          {user.role !== "Agency Manager" && (
            <Route exact path="/payment-file" component={PaymentFile} />
          )}
          <Route path="/notification" component={NotificationPage} />
          <Route path="/contacts" component={ContactsPage} />
          <Route path="/website" component={WebsitePage} />
          <Route path="/overview/interviewList" component={InterviewList} />
          {/* <Route path="/campaign" component={CampaignPage} /> */}
          <Route path="/campaign/sms-campaign" component={SmsCampaign} />
          <Route path="/campaign/select-sms-campaign" component={SelectSmsCampaign} />
          <Route path="/campaign/add-sms-campaign" component={AddSmsCampaign } />
          <Route path="/campaign/campaign" component={Campaign} />
          <Route path="/campaign/launch-campaign" component={LaunchCampaign} />

          <Route path="/digital/digital-campaign" component={FieldData } />
          <Route path="/telecalling/telecalling" component={FieldData } />
          <Route path="/field/field" component={FieldData } />
          <Route path="/legal/legal" component={FieldData } />
          <Route path="/payments/payments" component={FieldData } />

          <Route exact path="/import/loan" component={LoanDataPage} />
          <Route exact path="/import/Apr" component={AprPage} />
          <Route
            exact
            path="/import/Verification_Import"
            component={VerificationPage}
          />
          <Route
            exact
            path="/import/Verification_Allocation"
            component={Verification_Allocation}
          />
          <Route exact path="/import/Search_CPV" component={Search_CPV} />
          <Route exact path="/import/CPV_Data" component={CPV_Data} />
          <Route
            exact
            path="/performance/AprReports"
            component={AprReportsPage}
          />
          <Route
            exact
            path="/import/loanforlegal"
            component={LoanDataForLegalPage}
          />
          <Route exact path="/import/users" component={UserDataPage} />
          <Route path="/import/allocation" component={AllocationDataPage} />
          <Route path="/import/disposition" component={DispositionPage} />
          <Route path="/import/payment" component={PaymentPage} />
          <Route exact path="/import/paidfile" component={PaidFile} />
          <Route path="/legal/upload" component={Upload} />
          <Route path="/legal/notice" component={GeneratedData} />
          <Route path="/legal/track" component={Track} />
          <Route path="/LeaveApplication" component={LeaveApplication} />
          <Route path="/LeaveApply" component={LeaveApply} />
          {/* <Route path="/LeaveTeam" component={LeaveTeam} /> */}
          <Route path="/leaveDashTeamTabs" component={leaveDashTeamTabs} />
          <Route path="/LeaveException" component={LeaveException} />
          <Route path="/LeaveApplyException" component={LeaveApplyException} />
          <Route path="/ExceptionApply" component={ExceptionApply} />
          <Route
            path="/CollectionTrendMonth"
            component={CollectionTrendMonth}
          />
          <Route path="/CollectionTrendDays" component={CollectionTrendDays} />
          <Route
            path="/EmailSegmentation/Upload"
            component={UploadSegmentation}
          />
          <Route path="/EmailSegmentation/track" component={TrackEmail} />
          <Route path="/loan/details" component={DetailsPage} />
          <Route path="/today/details" component={TodayDetailsPage} />
          <Route path="/teleCaller/details" component={TeleCallerDetailsPage} />
          <Route path="/data-analytic/faq" component={FAQPage} />
          <Route exact path="/help" component={HelpPage} />
          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    );
  }
  else {
    return(<></>)
  }
  
}