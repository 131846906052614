export const prod = {
    baseURL: 'https://mjgp3zsss0.execute-api.ap-south-1.amazonaws.com/Production',
    X_Api_Key: 'TpupnebKGCas9jOiUxH1B97sPcb4glmPaUM6JOHX'
};

export const dev = {
    //baseURL: 'https://gsg8hi850j.execute-api.ap-south-1.amazonaws.com/Dev',
    //X_Api_Key: 'xvF3vMk6m88pWAAuyoiCp6diSYG1gMSkFZ2pxOVa'
    // baseURL: 'https://dev-cms-api.daqi.in/'
    // baseURL: 'https://dev-ecs-cms.daqi.in/'
    baseURL: 'https://prod-ecs-cms.daqi.in/'
};
export const qa = {
    baseURL: 'https://gsg8hi850j.execute-api.ap-south-1.amazonaws.com/Dev',
    X_Api_Key: '2V7I5AVIbZ4ZL8jTiN1HG9EpyQuafteDaFa6gP1g'
}

export const google_api_key = {
    api_key: 'AIzaSyBBaZJgVSNJbU3-rrMvKZpjOn2yv7zSa2M'
}

export const techdialer = {
    hostname:'https://dialer.acenna.in/api/cti'
}

export const UNASSIGNED_STATE_CODE = 'UN';

export const UNKNOWN_DISTRICT_KEY = 'Unknown';

export const STATE_NAMES = {
    AP: 'Andhra Pradesh',
    AR: 'Arunachal Pradesh',
    AS: 'Assam',
    BR: 'Bihar',
    CT: 'Chhattisgarh',
    GA: 'Goa',
    GJ: 'Gujarat',
    HR: 'Haryana',
    HP: 'Himachal Pradesh',
    JH: 'Jharkhand',
    KA: 'Karnataka',
    KL: 'Kerala',
    MP: 'Madhya Pradesh',
    MH: 'Maharashtra',
    MN: 'Manipur',
    ML: 'Meghalaya',
    MZ: 'Mizoram',
    NL: 'Nagaland',
    OR: 'Odisha',
    PB: 'Punjab',
    RJ: 'Rajasthan',
    SK: 'Sikkim',
    TN: 'Tamil Nadu',
    TG: 'Telangana',
    TR: 'Tripura',
    UT: 'Uttarakhand',
    UP: 'Uttar Pradesh',
    WB: 'West Bengal',
    AN: 'Andaman and Nicobar Islands',
    CH: 'Chandigarh',
    DN: 'Dadra and Nagar Haveli and Daman and Diu',
    DL: 'Delhi',
    JK: 'Jammu and Kashmir',
    LA: 'Ladakh',
    LD: 'Lakshadweep',
    PY: 'Puducherry',
    TT: 'India',
    [UNASSIGNED_STATE_CODE]: 'Unassigned',
};
  
const stateCodes = [];
const stateCodesMap = {};
Object.keys(STATE_NAMES).map((key, index) => {
    stateCodesMap[STATE_NAMES[key]] = key;
    stateCodes.push({code: key, name: STATE_NAMES[key]});
    return null;
});
export const STATE_CODES = stateCodesMap;
export const STATE_CODES_ARRAY = stateCodes;

export const MAP_STATS_TABLE_CONFIG = {
    cell2: {
        keyName: 'principalOutStanding',
        displayName: 'Principal OutStanding Amount (Cr.)'
    },
    cell3: {
        displayName: 'Header 3'
    },
    cell4: {
        displayName: 'Header 4'
    },
    cell5: {
        displayName: 'Header 5'
    },
    cell6: {
        displayName: 'Header 6'
    },
    cell7: {
        displayName: 'Header 7'
    },
    cell8: {
        displayName: 'Header 8'
    },
    cell9: {
        displayName: 'Header 9'
    },
    cell10: {
        displayName: 'Header 10'
    },
    cell11: {
        displayName: 'Header 11'
    },
    cell12: {
        displayName: 'Header 12'
    },
    cell13: {
        displayName: 'Header 13'
    },
    cell14: {
        displayName: 'Header 14'
    }    
};

export const INDIA_MAP_BORDER = [];

export const STATE_WISE_MAP_PLOT = [];

export const DISTRICT_WISE_MAP_PLOT = [];
