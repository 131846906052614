import React, { useState, useEffect } from "react";
import * as XLSX from 'xlsx';
import { MdOutlineKeyboardArrowRight, MdOutlineKeyboardVoice, MdOutlineSms } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io5";
import { getData, postData } from '../../store/utils.js';
// import launchCampaign from "../../files/launch-campaign";
import launchCampaign from "../../files/launch-campaign.png"
import { NavLink } from "react-router-dom/cjs/react-router-dom.js";
import { MdOutlineMessage, MdArrowDropDown } from "react-icons/md";
import { FaRegFileAlt, FaRegTimesCircle } from "react-icons/fa";
import Select, { AriaOnFocus } from 'react-select';
import Papa from "papaparse";
import s3BucketConfig from '../../utils/s3BucketConfig';
import { useDispatch, useSelector } from "react-redux";
// import { colourOptions } from "./docs/data"
import customSwal from "../../components/customSwal.js";
import ReactModal from "react-modal";
import { Tab, Tabs } from "react-bootstrap";
import SelectallCampaign from "../../components/SelectallCampaign.js";
import styled from 'styled-components'
import {
    Card,
    CardBody,
    CardHeader,
} from "../../../_metronic/_partials/controls";

const Styles = styled.div`
    table {
      th{
        padding: 0px;
      }
    }
  `;
let s3Bucket;
function LaunchCampaign() {

    const getCurrentDateTimeWithCampaign = () => {
        const now = new Date();
        const day = now.getDate();
        const month = now.toLocaleString('en-US', { month: 'short' });
        const year = now.getFullYear();
        const hours = now.getHours();
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        const formattedTime = `${hours % 12 || 12}:${minutes} ${ampm}`;
        return `${day} ${month} ${year}, ${formattedTime} Campaign`;
    };

    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState("25th Nov24 - 08:19 PM Campaign");
    const [selectedOption, setSelectedOption] = useState(null);
    const [showdropdown, setShowdropdown] = useState(false)
    const [templateOptions, setTemplateOptions] = useState([]);
    const [selectedSender, setSelectedSender] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [templateBodyPreview, setTemplateBodyPreview] = useState('');
    const [senderOptions, setSenderOptions] = useState([]);
    const [isLoadingOn, setIsLoadingOn] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [templateId, setTemplateId] = useState()
    const [campaignName, setCampaignName] = useState(getCurrentDateTimeWithCampaign());
    const [campaignId, setCampaignId] = useState()
    const [contactData, setcontactData] = useState('')
    const [selectedFile, setSelectedFile] = useState(null);
    const [FileNameData, setFileNameData] = useState(null);
    const [tableData, setTableData] = useState([]); // To store CSV data
    const [headers, setHeaders] = useState([]); // To store CSV headers
    const [childData, setChildData] = useState("");
    const [uploadCsvData, setUploadCsvData] = useState("");
    const user = useSelector(state => state.entities.auth.data);
    const [pageLoader, setPageLoader] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorAlertMsg, setErrorAlertMsg] = useState(null);
    const [successAlertMsg, setSuccessAlertMsg] = useState(null);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [postFileData, setPostFileData] = useState();
    const [insertFileId, setInsertFileId] = useState();
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [addTempData, setAddTempData] = useState();
    const [uploadCsvResponse, setUploadCsvResponse] = useState();
    const [senderValue, setSenderValue] = useState();
    const persistData = localStorage.getItem('persist:root');
    const [showSender, setShowSender] = useState("DAQI (IN)");
    const [senderModalIsOpen, setSenderIsOpen] = React.useState(false);
    const [templateModalIsOpen, setTemplateIsOpen] = React.useState(false);
    let userId;
    if (persistData) {
        const parsedPersistData = JSON.parse(persistData);
        const authData = JSON.parse(parsedPersistData.entities)?.auth?.data;
        userId = authData?.userId;
    }
    const [senderDetails, setSenderDetails] = useState({
        senderName: "",
        senderDescription: "",
        entityId: "",
    });
    
    const handleSubmit = async () => {
        if (!senderValue) {
            customSwal.fire({
                icon: "error",
                title: "Error!",
                text: "Please select the Sender Id"
            })
            return;
        } else if (!selectedTemplate) {
            customSwal.fire({
                icon: "error",
                title: "Error!",
                text: "Please select the template"
            });
            return;
        }
        if (childData.key === "addCampaign") {
            if (uploadCsvData) {
                try {
                    setIsLoadingOn(true); // Show loader
                    const uploadCsvResponse = await uploadCSV();
                    setAddTempData(uploadCsvResponse);
                } catch (error) {
                    console.error("Error during campaign creation:", error);
                    customSwal.fire({
                        icon: "error",
                        title: "Error!",
                        text: error.message || "Something went wrong during the campaign creation process.",
                    });
                } finally {
                    setIsLoadingOn(false); // Hide loader
                }
            } else {
                customSwal.fire({
                    icon: "error",
                    title: "Error!",
                    text: "Upload CSV data is missing. Please upload the file.",
                });
            }
        }
        if(childData.key == "selectCampaign") {            
            const selectFilterData = {
                file: childData.rows,
                templateId: selectedTemplate.value,
                smsFileName: campaignName,
                userId: userId
            };    
            setIsLoadingOn(true);
            try {
                const selectTempData = await postData("sms/selectSmsCampaign", selectFilterData);
                if (selectTempData?.statusCode === 200) {
                    setIsOpen(false);
                    customSwal.fire({
                        icon: "success",
                        title: "Success",
                        text: selectTempData?.message,
                    });
                    window.location.href = '/campaign/sms-campaign';
                } else {
                    customSwal.fire({
                        icon: "error",
                        title: "Error!",
                        text: selectTempData?.message || "Something went wrong!!!",
                    });
                }
            } catch (error) {
                console.error("Error processing campaign:", error);
                customSwal.fire({
                    icon: "error",
                    title: "Error!",
                    text: error?.message || "Failed to process campaign",
                })
            } finally {
                setIsLoadingOn(false);
            }
        }
    }

    const handleAddSender = async () => {
        const selectFilterData = {
            senderName: senderDetails.senderName,
            senderDescription: senderDetails.senderDescription,
            entityId: senderDetails.entityId,
            createdBy: userId,
            updatedBy: userId
        };    
        setIsLoadingOn(true);
        try {
            const selectSenderData = await postData("sms/addSender", selectFilterData);
            if (selectSenderData?.statusCode === 200) {
                setIsOpen(false);
                customSwal.fire({
                    icon: "success",
                    title: "Success",
                    text: selectSenderData?.message,
                });
                setSenderIsOpen(false)
                window.location.reload();
            } else {
                customSwal.fire({
                    icon: "error",
                    title: "Error!",
                    text: selectSenderData?.message || "Something went wrong!!!",
                });
            }
        } catch (error) {
            console.error("Error processing campaign:", error);
            customSwal.fire({
                icon: "error",
                title: "Error!",
                text: error?.message || "Failed to process campaign",
            })
        } finally {
            setIsLoadingOn(false);
        }
    };

    const handleAddSenderChange = (e) => {
        const { name, value } = e.target;
        setSenderDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
    };

    const customOptions = [...senderOptions, { value: "+ Add Sender", label: "+ Add Sender" }];

    async function uploadCSV() {
        try {
            if (uploadCsvData) {
                setPageLoader(true);
                s3Bucket = await s3BucketConfig("CAMPAINGN_UPLOADS");
                if (s3Bucket) {
                    let financierId = "";
                    for (const iterator of uploadCsvData) {
                        if (iterator.financierId) {
                            financierId = iterator.financierId;
                        }
                    }
                    
                    const fileName = selectedFile.name;                    
                    const params = {
                        ACL: 'public-read',
                        Body: selectedFile,
                        Key: fileName
                    };
                    s3Bucket.putObject(params)
                        .on('httpUploadProgress', (evt) => {
                            // setProgress(Math.round((evt.loaded / evt.total) * 100))
                        })
                        .send(async(err) => {  
                            if (err) {
                                setPageLoader(false);
                                setErrorAlertMsg("Something went wrong. Please try again.");
                                setShowErrorAlert(true);
                                setTimeout(() => {
                                    setErrorAlertMsg("");
                                    setShowErrorAlert(false);
                                }, 4000);
                            }
                            else {
                                setSelectedFile(null);
                                const postData = {
                                    'feature': "LONG_RUNNING_FILE_UPLOAD",
                                    'userId': user.userId,
                                    'fileName': fileName,
                                    'operation': "CAMPAINGN_UPLOADS",
                                    'totalRecord': contactData,
                                    'financierId': financierId || "",
                                    'campaignName': campaignName
                                };
                                setPostFileData(postData);
                                setPageLoader(true);
                                const uploadResponse = await saveUploadedFileData(postData);                               
                                setPageLoader(false);
                                // return uploadResponse;
                            }
                        });
                }
                else {
                    setPageLoader(false);
                    setErrorAlertMsg("Something went wrong. Please try again.");
                    setShowErrorAlert(true);
                    setTimeout(() => {
                        setErrorAlertMsg("");
                        setShowErrorAlert(false);
                    }, 4000);
                }
            }
        } catch (e) {
            console.log("HIIII 444 === ");                
            setPageLoader(false);
            setErrorAlertMsg("Something went wrong. Please try again.");
            setShowErrorAlert(true);
            setTimeout(() => {
                setErrorAlertMsg("");
                setShowErrorAlert(false);
            }, 4000);
        }
    }

    const saveUploadedFileData = async (postFileData) => {
        try {            
            setIsLoadingOn(true);
            let filterData = {
                feature: postFileData.feature,
                userId: postFileData.userId,
                operation: postFileData.operation,
                fileName: postFileData.fileName,
                totalRecord: postFileData.totalRecord,
                financierId: postFileData.financierId,
                campaignName: postFileData.campaignName,
            };
            const uploadResponse = await postData(`loan/saveFileUploadRequest`, filterData);
            setInsertFileId(uploadResponse.data.id);
            setUploadCsvResponse(uploadResponse)
            await fetchData(uploadResponse.data.id);
        } catch (error) {
            console.error("Error saving file upload request:", error);
            setErrorMessage("Error saving file upload request");
            return null; 
        } finally {
            setIsLoadingOn(false);
            setIsOpen(false);
        }
    };
    
    const fetchData = async (insertId) => {
        try {
            setIsLoadingOn(true);
            const response = await getData(`loan/loanUploads?id=${insertId}&templateId=${selectedTemplate.value}&smsFileName=${campaignName}`);
            setAddTempData(response);
            console.log("addTempData === ", addTempData);   
            if (response) {
                setIsOpen(false);
                customSwal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Sms sending is in progress, please wait.",
                }); 
                window.location.href = '/campaign/sms-campaign';
            } else {
                console.log("No data available to set CampaignId.");
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorMessage('Error fetching data');
        } finally {
            setIsLoadingOn(false);
            setIsOpen(false);
        }
    };
    useEffect(() => {
        if (!isInitialLoad && postFileData) {
            (async () => {
                await saveUploadedFileData(postFileData);
            })();
        }
    }, [postFileData]);

    const showSenderList = async () => {
        try {
            const response = await getData('sms/getSender');
            const data = response?.data;
            const newSenderData = data.map(item => ({
                value: item.senderName,
                label: item.senderName,
            }));

            setSenderOptions(newSenderData || []);
        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorMessage('Error fetching data');
        } finally {
            setIsLoadingOn(false);
        }
    };
    useEffect(() => {
        showSenderList();
    }, []);

    const showTemplateList = async (selectedOption) => {
        try {
            const senderValue = selectedOption.value
            setSenderValue(senderValue)
            console.log("senderValue === ", senderValue);
            const response = await getData(`sms/getTemplate?senderId=${senderValue}`);
            const data = response?.data;

            const newTemplateData = data.map(item => ({
                value: item.id,
                label: item.templateName,
                templateBody: item.templateBody,
            }));

            setTemplateOptions(newTemplateData || []);
        } catch (error) {
            console.error('Error fetching data:', error);
            setErrorMessage('Error fetching data');
        } finally {
            setIsLoadingOn(false);
        }
    };
    useEffect(() => {
        showSenderList();
    }, []);

    const handleSenderChange = async (selectedOption) => {
        if (selectedOption.value === "+ Add Sender") {
            setSenderIsOpen(true);
        } else {
            setSelectedSender(selectedOption);
            setShowSender(selectedOption.value);
            await showSenderList();
            await showTemplateList(selectedOption);
        }
    };

    const handleTemplateChange = (selectedOption) => {
        setSelectedTemplate(selectedOption);
        setTemplateId(selectedOption.value)
        setTemplateBodyPreview(selectedOption.templateBody || '');
    };

    const advanceSearch = () => {
        setShowdropdown((prevState) => !prevState); // Toggle state
    }
    const closeModal = () => {
        setIsOpen(false);
    };

    const importModal = () => {
        setIsOpen(true);
    }

    const handleClick = () => {
        setIsEditing(true)
    }
    const handleChange = (e) => {
        setCampaignName(e.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };
    // Callback function to receive data from the child
    const handleChildData = (data) => {
        setChildData(data);
        setUploadCsvData(data?.rows)
        setcontactData(data?.rowLength)
        setIsOpen(false);
        setTableData([]); // Reset table data
        setHeaders([]);    // Reset headers
        setFileNameData(null); // Reset file name data
    };

    // handle file upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const nameOfFile = event.target.files[0].name;
        if (!file) return;

        // Parse the CSV file
        Papa.parse(file, {
            header: true, // Automatically read the first row as headers
            skipEmptyLines: true,
            complete: (result) => {
                const { data, meta } = result;
                setTableData(data); // Set data rows
                setHeaders(meta.fields); // Set headers
            },
            error: (error) => {
                console.error("Error parsing CSV:", error);
            },
        });
        setSelectedFile(event.target.files[0]);
        setFileNameData(nameOfFile)
        event.target.value = '';
    }

    const submitData = () => {
        if (!selectedFile) {
          console.error("No file selected!");
          return;
        }
      
        const reader = new FileReader();
        reader.onloadend = () => {
          let base64String = reader.result.replace(/^data:.*;base64,/, ""); // Remove prefix
      
          // Construct modalData inside the callback
          const modalData = {
            rows: base64String,
            rowLength : tableData?.length,
            key: "addCampaign",
          };
          handleChildData(modalData); // Send data to parent
        };
      
        reader.readAsDataURL(selectedFile); // Read the file as Base64
      };

    const customStyles = {
        menu: (provided) => ({
            ...provided,
            maxHeight: "200px",
            overflowY: "auto", // Ensure scrolling works
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            position: "relative",
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#007bff" : state.isFocused ? "#e9ecef" : "#fff",
            color: state.isSelected ? "#fff" : "#000",
            cursor: "pointer",
        }),
        control: (provided) => ({
            ...provided,
            borderColor: "#007bff",
            boxShadow: "none",
            "&:hover": {
                borderColor: "#0056b3",
            },
        }),
    };

    const CustomMenuList = (props) => (
        <div>
            <div style={{ maxHeight: "160px", overflowY: "auto" }}>{props.children}</div>
            <div
                style={{
                    position: "sticky",
                    bottom: 0,
                    backgroundColor: "#f8f9fa",
                    padding: "10px",
                    borderTop: "1px solid #ccc",
                    textAlign: "center",
                    zIndex: 10,
                }}
                onClick={() => setSenderIsOpen(true)}
            >
                <strong style={{ color: "#00D464", cursor: "pointer" }}>
                    + Add Sender
                </strong>
            </div>
        </div>
    );

    return (
        <>
        {isLoadingOn && (
            <div className="fullscreen-loader">
                <div className="spinner"></div>
                <p>Processing...</p>
            </div>
        )}
        <div className="m-0 loanDataImport bg-white launchcampaign">
            <div className="row p-5 cardsec">

                <div className="col-4">
                    <div className="bluebox">
                        <div className="boxhd">Campaign Name</div>
                        <div className="boxinput mt-1" onClick={handleClick}>
                            {isEditing ? (
                                <input
                                    type="text"
                                    value={campaignName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            ) : (
                                <span>{campaignName}</span>
                            )}
                        </div>
                        <div className="datename pb-5 mt-5">
                            <hr />
                        </div>
                    </div>
                </div>
                <div className="col-4">
                    <div className="bluebox">
                        <div className="topSec">
                            <div className="boxhd">Sender Id</div>
                            <div className="hdname mb-2 mt-1">{showSender}{" "}<span className="ml-3"><MdArrowDropDown onClick={advanceSearch} /></span></div>
                            {/* {showdropdown && (
                                <div className="dropdwonlist">
                                    <Select
                                        placeholder="Select Sender Id"
                                        value={selectedSender}
                                        onChange={handleSenderChange}
                                        options={customOptions}
                                    />
                                </div>
                            )} */}
                            {showdropdown && (
                                <div style={{ position: "relative" }}>
                                    <Select
                                        placeholder="Select Sender Id"
                                        value={selectedSender}
                                        onChange={handleSenderChange}
                                        options={customOptions}
                                        styles={customStyles}
                                        components={{ MenuList: CustomMenuList }}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="btmsec mt-5">
                            <div className="boxhd">Select the Route to send SMS</div>
                            <div className="mt-2">
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="inlineRadio1"
                                        value="marketing"
                                        defaultChecked // Set default checked
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">
                                        Marketing
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="inlineRadio2"
                                        value="transactional"
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        Transactional
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ReactModal for Add Sender */}
                    <ReactModal
                        isOpen={senderModalIsOpen}
                        onRequestClose={() => setSenderIsOpen(false)}
                        style={{
                            overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" },
                            content: {
                                margin: "auto",
                                padding: "20px",
                                maxWidth: "30%",
                                maxHeight: "380px",
                                borderRadius: "10px",
                                border: "1px solid #ccc",
                                boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)",
                            },
                        }}
                        ariaHideApp={false}
                        contentLabel="Add Sender Modal"
                    >
                        <button
                            className="btn btn-link modalCloseBtn"
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                fontSize: "1rem",
                                lineHeight: "1",
                                color: "#007bff",
                            }}
                            onClick={() => setSenderIsOpen(false)}
                        >
                            <FaRegTimesCircle />
                        </button>
                        <div className="modalBody">
                            <h4 className="mb-4">Add Sender</h4>
                            <div className="form-group">
                                <label htmlFor="senderName">Sender Name</label><span>*</span>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="senderName"
                                    name="senderName"
                                    value={senderDetails.senderName}
                                    onChange={handleAddSenderChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="senderDescription">Sender Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="senderDescription"
                                    name="senderDescription"
                                    value={senderDetails.senderDescription}
                                    onChange={handleAddSenderChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="entityId">Entity ID</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="entityId"
                                    name="entityId"
                                    value={senderDetails.entityId}
                                    onChange={handleAddSenderChange}
                                />
                            </div>
                            <div className="text-center mt-4">
                                <button className="btn btn-primary" onClick={handleAddSender}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </ReactModal>
                </div>
                <div className="col-4">
                    <div className="bluebox">
                        <div className="topSec">
                            <div className="boxhd">Sender to</div>
                            <div className="hdnameblue mb-2 mt-1">{contactData ? contactData : "0"} Contacts</div>
                        </div>
                        <div className="btmsecbtns">
                            <button type="button" className="btn btn-primary" onClick={importModal}>Import</button>
                        </div>
                    </div>
                </div>

            </div>

            <div className="row px-5 mt-3">
                <div className="col-4">
                    <Select
                        placeholder="Select Template"
                        value={selectedTemplate}
                        onChange={handleTemplateChange}
                        options={templateOptions}
                    />
                </div>
            </div>
            <div className="row px-5 pb-5 cardsec tempsec">
                <div className="col-8 mt-5">
                    {/* Read-only textarea for now */}
                    <textarea
                        className="form-control"
                        rows={10}
                        value={templateBodyPreview}
                        readOnly
                    ></textarea>
                    <button
                        className="btn btn-primary mt-2"
                        type="button"
                        onClick={handleSubmit}
                    >
                        Send
                    </button>
                </div>
                <div className="col-4">
                    <div className="bluebox prevSec mt-3">
                        <div className="msgPre">
                            <div>
                                <MdOutlineMessage style={{ fontSize: "60px" }} />
                            </div>
                            <div className="msgprev">{templateBodyPreview || "Your message preview will display here"}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Modal Section */}
            <ReactModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{
                    overlay: { backgroundColor: "rgba(0, 0, 0, 0.6)" },
                    content: {
                        margin: "auto",
                        padding: "20px",
                        maxWidth: "60%",
                        maxHeight: "550px",
                        borderRadius: "10px",
                        border: "1px solid #ccc",
                        boxShadow: "0 5px 15px rgba(0, 0, 0, 0.5)",
                    },
                }}
                ariaHideApp={false}
                contentLabel="Run Campaign Modal"
            >
                <button
                    className="btn btn-link modalCloseBtn"
                    style={{ position: "absolute", top: "10px", right: "10px", fontSize: "1.5rem", lineHeight: "1", color: "#007bff" }}
                    onClick={closeModal}
                >
                    <FaRegTimesCircle />
                </button>
                <div className="modalBody launchCampainModabody">
                    <Tabs
                        defaultActiveKey="add"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="add" title="Add">
                        

                            {
                                tableData && tableData.length > 0 ? (
                                    <div className="commontable uploadcsvtable">
                                    <button type="button" onClick={() => submitData()} className='btn mt-3 mb-3 btn-primary font-weight-bold transformBtn agentTransformBtn submitBtn'>Submit</button>
                                        <div className="tblScroll" style={{height: "400px"}}>
                                    <table border="1" className="loanUpload bucketWiseTable" style={{ width: "100%", textAlign: "left" }}>
                                        <thead className="tableHead">
                                            <tr className="tableHeader" colspan="1">
                                                {headers.map((header, index) => (
                                                    <th key={index}>{header}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {headers.map((header, cellIndex) => (
                                                        <td class="tableTd" key={cellIndex}>{row[header]}</td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                    </div>
                                ) : (
                                    <div className="addsec">
                                        <div className="centersec">
                                            <div className="filuploadtop">
                                                <input
                                                    type="file"
                                                    id="upload"
                                                    accept=".csv"
                                                    onChange={handleFileUpload}
                                                />
                                                <FaRegFileAlt className="fileIcon" />
                                                <div className="mt-2 dropFile">
                                                    Drop your .CSV or Excel file here to upload
                                                </div>
                                            </div>
                                            <div className="mt-5 or">OR</div>
                                            <div className="mt-5">
                                                <input type="file" id="upload" hidden />
                                                <label
                                                    htmlFor="upload"
                                                    className="btn btn-primary uploadFile"
                                                >
                                                    Choose file
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </Tab>
                        <Tab eventKey="select" title="Select">
                            <SelectallCampaign sendDataToParent={handleChildData} />
                        </Tab>
                    </Tabs>
                </div>
            </ReactModal>
        </div>
        </>
    )
}

export default LaunchCampaign