import AWS from 'aws-sdk';
import environment from './../../env.json';
import { getData, postData } from '../store/utils.js';

async function s3BucketConfig(operation, featureType = 'COLLECTION') {
    try {
        const data = {
            'feature': "BUCKET_CONFIG",
            'operation': operation,
            'featureType': featureType
        };
        const headers = {
            'X-Api-Key': environment.apiKey,
            'Content-Type': 'application/json',
        }
        const response = await postData('loan/s3BucketConfig', data, headers);
        console.log("response 111 === ", response.data);
        
        if ( response && response.data ) {
            const responseData = response.data;
            console.log("Data received: ", responseData);

            AWS.config.update({
                accessKeyId: responseData.accessKeyId,
                secretAccessKey: responseData.secretAccessKey,
            });

            try {
                const s3Bucket = new AWS.S3({
                    params: { Bucket: responseData.bucketName },
                    region: responseData.region,
                });
                console.log("S3 Bucket instance: ", s3Bucket);
                return s3Bucket;
            } catch (error) {
                console.error("Error creating S3 Bucket: ", error);
                throw new Error("Failed to create S3 Bucket instance.");
            }
        } else {
            throw new Error("Failed to fetch bucket configuration.");
        }
    } catch (error) {
        console.error("Error in s3BucketConfig: ", error);
        throw error;
    }
}

export default s3BucketConfig;